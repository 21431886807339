/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useAddItemToCart, useUpdateItemsOnCart } from "app/helpers/cart/hooks";
import CustomPriceContext from "../../customPriceContext";
import Price from "core/components/price";
import Icon from "app/assets/icon/icon";
import useCustomer from "app/state/hooks/customer/useCustomer";
import Login from "app/pages/customer/login";
import ModalContext from "../../../../../core/components/modal/ModalContext";
import Downloads from "./components/downloads";
import { generate3DImage } from "../../utils";
import { gql } from "graphql.macro";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  useFavorites,
  useToggleFavoriteProductParam,
  useWishlists,
} from "../../../collections/hooks";
import NameFormModal, {
  buildNameSchema,
  buildNameValues,
} from "../../../../layout/NameFormModal";
import { useFormik } from "formik";
import themeConfiguration from "../../../../../config/themeConfiguration";
import CollectionsContext from "../../../../layout/collections/CollectionsContext/CollectionsContext";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { checkIfItemIsChecked } from "app/pages/collections/components/WishlistsModal/wishlistHelper";
import Summary from "./components/summary";
import { inStockLogicMethod, quoteRequestButton } from "./helper";
import { pathnameIncludes } from "app/utils/utils";
import InStock from "../../customPDP/components/steps/components/step/LiveEdgeModal/ProductInfo/InStock";

export const CART_RESTRICTED_CATEGORIES = gql`
  query getCartRestrictedCategories {
    storeConfig {
      jola_category_cart_restriction_category_ids
    }
  }
`;
const UPLOAD_QUOTE_CONFIGURATOR_IMAGE = gql`
  mutation uploadQuoteConfiguratorImage($name: String!, $data: String!) {
    uploadQuoteConfiguratorImage(
      input: {
        build_your_own_image: {
          name: $name
          type: "image/png"
          base64_encoded_data: $data
        }
      }
    ) {
      build_your_own_image
    }
  }
`;

const Toolbar = ({
  sku,
  name,
  calculateOptions,
  id,
  isCustom = false,
  downloadTearSheet,
  player = undefined,
  image,
  product,
  isEdit = false,
  optionState = false,
  customName = "",
  top = false,
  base = false,
  liveEdgePrice = null,
  numOfBases = 1,
  description,
  finishError,
  setFinishError,
}) => {
  React.useEffect(() => {
    if (
      !window.location.search &&
      window.location.pathname.includes("build-your-own") &&
      calculateOptions()?.length === 9
    )
      history.replace({
        pathname: location.pathname,
        search: calculateOptions()[
          calculateOptions().length - 1
        ]?.value_string?.split("?")[1],
      });
  }, [calculateOptions]);
  const { data, loading, error } = useQuery(CART_RESTRICTED_CATEGORIES, {
    fetchPolicy: "no-cache",
  });

  const isAddingToCartForbidden = useMemo(
    () => 
      {
        const restrictedCategories = data?.storeConfig?.jola_category_cart_restriction_category_ids?.split(",");
        if(!restrictedCategories || !restrictedCategories?.length) return false;
        const foundRestricted = product?.categories?.find((category) =>
          restrictedCategories?.includes(category?.id + '')
        );
        return foundRestricted;
      }
      , [data, product]);
  const history = useHistory();
  const location = useLocation();

  const mappedOptions = useMemo(() => {
    return product?.options?.reduce(
      (accumulator, option) =>
        option.category_options
          ? {
              ...accumulator,
              [option.category_options.toLowerCase().split(" ").join("_")]: (
                accumulator[
                  option?.category_options?.toLowerCase().split(" ").join("_")
                ] || []
              ).concat(option),
            }
          : accumulator,
      {}
    );
  }, [product?.options]);
  const {
    state: { price },
  } = useContext(CustomPriceContext);
  const [buildSummary, setBuildSummary] = useState(false);
  const [buildHoverIcon, setBuildHoverIcon] = useState(false);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  const { showModal, hideModal } = useContext(ModalContext);
  const [addItemToCart, addItemToCartResult] = useAddItemToCart();
  const [updateItemOnCart, result] = useUpdateItemsOnCart();
  const favorites = useFavorites();
  const wishlists = useWishlists();
  const toggleFavorite = useToggleFavoriteProductParam();
  const [uploadQuoteConfiguratorImage] = useMutation(
    UPLOAD_QUOTE_CONFIGURATOR_IMAGE
  );
  const customer = useCustomer().isLoggedIn();

  const form = useFormik({
    initialValues: { name: name ?? buildNameValues.name },
    validationSchema: buildNameSchema,
    // onSubmit: () => {
    //   return saveBuild();
    // },
  });

  const getMonogramIdsString = (productWithOptions) => {
    let options = productWithOptions?.options
      ? productWithOptions.options
      : productWithOptions;
    if (!options) return;
    let obj = {};
    let monogramString = options?.find((option) =>
      option?.value_string?.toString()?.includes("{")
    )?.value_string;
    const monogramObj = monogramString && JSON.parse(monogramString);
    for (const key in monogramObj) {
      obj = { ...obj, [key]: monogramObj[key] };
    }
    // let monogramUrl = options
    //   ?.find((option) =>
    //     option?.value_string?.toString()?.includes("build-your-own/monogram")
    //   )
    //   ?.value_string?.split("?")?.[1];
    const urlParams = new URLSearchParams(window.location.href);
    let finishId = urlParams.get("finish");
    let contrastDrawersId = urlParams.get("contrastDrawers");

    if (window.location.search.includes("finish") && !obj?.finish)
      obj["finish"] = { id: finishId };
    if (
      window.location.search.includes("contrastDrawers") &&
      !obj?.contrastDrawers
    )
      obj["contrastDrawers"] = { id: contrastDrawersId };

    const { model, contrastDrawers, striping, finish } = obj;
    const newSortedObj = {
      model,
      contrastDrawers,
      striping,
      finish,
    };

    return Object.keys(newSortedObj)
      .map((key) => `${key}=${newSortedObj[key]?.id}`)
      .join("&");
  };

  const removeFromString = useCallback((urlString) => {
    let splitUrl = urlString?.split("&");
    splitUrl = splitUrl?.filter(
      // @ts-ignore
      (item) =>
        !item.includes("upcharge") &&
        !item.includes("throw_pillow_contrast_welt") &&
        !item.includes("fabric_contrast_welt") &&
        !item.includes("leather_contrast_welt")
    );
    return `${splitUrl?.join("&")}`;
  }, []);

  let areOptionsSame = useMemo(() => {
    let options = window.location.pathname.includes("monogram")
      ? getMonogramIdsString(calculateOptions())
      : calculateOptions()?.length > 0
      ? calculateOptions()
      : product.defaultOptions;

    if (
      isEqual(
        removeFromString(location.search.replace("&edit=true", "")),
        window.location.pathname.includes("monogram")
          ? `?${options}`
          : `?${removeFromString(
              options?.[options?.length - 1]?.value_string
                ?.toString()
                ?.split("?")[1]
            )}`
      )
    )
      return true;
    return false;
  }, [
    location.search,
    product.defaultOptions,
    calculateOptions,
    wishlists,
    getMonogramIdsString,
  ]);

  const isFinishReqDone = useMemo(
    () =>
      !product?.finish_req ||
      optionState?.finish ||
      (window.location.pathname.includes("monogram") &&
        getMonogramIdsString(calculateOptions())?.includes("finish")),
    [product?.finish_req, optionState?.finish, getMonogramIdsString]
  );

  const isInFavorites = useMemo(
    () =>
      favorites?.items.find(
        (favorite) => favorite.product.sku === product.sku
      ) && areOptionsSame,
    [favorites, areOptionsSame, product]
  );

  const { setSelectedProduct, setIsFavorite, setShowModal } = useContext(
    CollectionsContext
  );

  React.useEffect(() => setIsFavorite(areOptionsSame), [areOptionsSame]);

  const isOptionStateEmpty = useMemo(
    () =>
      product?.ffi_casegd === 1 || product?.ffi_fullyuph === 1 ? true : false,
    [product]
  );

  const handleShowModal = useCallback(() => {
    showModal(() => <Login hideModal={hideModal} />);
  }, [showModal, hideModal]);

  const concatToString = (name, value, optionStateValue) => {
    if (value) return optionStateValue.concat(`&${name}=${value}`);
    return optionStateValue;
  };

  const getBYOData = useCallback(
    (existInFavoritesAndProject) => {
      return generate3DImage(player).then((data) => {
        if (data) {
          data = data.substring(22, data.length);
          player.updateZoom(0, -1);
          player.update();
          uploadQuoteConfiguratorImage({
            variables: {
              name,
              data,
            },
          }).then((data) => {
            if (data?.data) {
              const foundOption = product.options.find(
                (option) =>
                  option.title.toLowerCase() === "build your own image"
              );
              let getJSONData;
              let options = calculateOptions();
              if (!window.location.pathname.includes("monogram")) {
                getJSONData = JSON.parse(
                  options?.find(
                    (option) =>
                      typeof option?.value_string === "string" &&
                      option?.value_string?.includes("frame")
                  )?.value_string
                );

                if (getJSONData?.accessoryLeft?.id)
                  options[options.length - 1].value_string = concatToString(
                    "accessoryLeft",
                    getJSONData?.accessoryLeft?.id,
                    options[options.length - 1].value_string
                  );
                if (getJSONData?.accessoryRight?.id)
                  options[options.length - 1].value_string = concatToString(
                    "accessoryRight",
                    getJSONData?.accessoryRight?.id,
                    options[options.length - 1].value_string
                  );
                if (getJSONData?.powerOption?.id)
                  options[options.length - 1].value_string = concatToString(
                    "powerOption",
                    getJSONData?.powerOption?.id,
                    options[options.length - 1].value_string
                  );
              }

              let productData = {
                sku,
                name,
                options: [
                  ...options,
                  {
                    id: foundOption.option_id,
                    value_string:
                      data.data.uploadQuoteConfiguratorImage
                        .build_your_own_image,
                  },
                ],
                custom_image: `${themeConfiguration.magento_url}${data.data.uploadQuoteConfiguratorImage.build_your_own_image}`,
                isFavorite: areOptionsSame,
              };
              if (existInFavoritesAndProject)
                return setSelectedProduct(productData);

              toggleFavorite(productData, productData?.options);
            }
          });
        }
      });
    },
    [
      calculateOptions,
      form.values.name,
      sku,
      player,
      uploadQuoteConfiguratorImage,
      toggleFavorite,
      setSelectedProduct,
    ]
  );

  const favoritesMethod = () => {
    if (isCustom) {
      let customProduct = { ...product };
      generate3DImage(player).then((data) => {
        if (data) {
          data = data.substring(22, data.length);
          uploadQuoteConfiguratorImage({
            variables: {
              name,
              data,
            },
          }).then((data) => {
            if (data?.data) {
              const foundOption = product.options.find(
                (option) =>
                  option.title.toLowerCase() === "build_your_own_image"
              );
              customProduct.custom_image = `${themeConfiguration.magento_url}${data.data.uploadQuoteConfiguratorImage.build_your_own_image}`;
              setSelectedProduct(customProduct);
              customProduct.isFavorite = areOptionsSame;

              toggleFavorite(product, [
                ...calculateOptions(),
                {
                  id: foundOption?.option_id,
                  value_string:
                    data.data.uploadQuoteConfiguratorImage.build_your_own_image,
                },
              ]);
            } else {
              console.error("Something went wrong.");
            }
          });
        }
      });
    } else {
      setSelectedProduct(product);
      toggleFavorite(product, calculateOptions());
    }
  };

  const addToWishlistMethod = (productWithOptions) => {
    setSelectedProduct(productWithOptions);
    product.defaultOptions = calculateOptions();

    let arr = wishlists.filter((wishlist) =>
      wishlist.items.some(
        (wishistitem) =>
          wishistitem?.product?.sku === product?.sku &&
          checkIfItemIsChecked(product, wishistitem)
      )
    );

    let itemExist =
      favorites?.items.find(
        (item) =>
          item.product.sku === product.sku &&
          checkIfItemIsChecked(product, item)
      ) && areOptionsSame;

    if (itemExist && arr.length === 1) {
      history.replace({
        pathname: location.pathname,
        search: "",
      });
      setSelectedProduct(null);
      if (isCustom) getBYOData();
      else toggleFavorite(product, calculateOptions());
    } else if (arr.length < 2 || !areOptionsSame) {
      setSelectedProduct(productWithOptions);
      if (isCustom) getBYOData();
      else toggleFavorite(product, calculateOptions());
    } else {
      if (areOptionsSame) setShowModal(true);

      getBYOData(true);
    }
  };

  const inStockLogic = useMemo(() => inStockLogicMethod(product, customer), [
    customer,
    product,
  ]);

  const beforeAddToFavorites = useCallback((productWithOptions) => {
    let str = "";
    if (window.location.pathname.includes("monogram")) {
      str = getMonogramIdsString(productWithOptions);
    }

    history.replace({
      pathname: location.pathname,
      search: !window.location.pathname.includes("monogram")
        ? productWithOptions?.options[
            productWithOptions?.options.length - 1
          ]?.value_string?.split("?")[1]
        : str,
    });
  }, []);

  return (
    <>
      <div className={"toolbar-wrapper"}>
        <div className={"toolbar-container"}>
          {/* <Breadcrumbs className={"toolbar-breadcrumbs"} sku={sku} /> */}
          <div className="down-part-wrapper">
            <div className={"toolbar-left"}>
              {customName ? (
                <h5 className={"custom-name text-uppercase"}>{customName}</h5>
              ) : null}
              <h1 className={"product-name"}>{name}</h1>
              {description && <h4>{description}</h4>}
              <div className={"toolbar-second-row"}>
                <h5 className={"product-sku"}>{sku}</h5>
              </div>
            </div>
            <div className={"toolbar-right"}>
              {customer ? (
                <>
                  <div className={"price-stock d-flex align-items-center"}>
                    <h2 className={"product-price"}>
                      Starting at{" "}
                      <Price
                        price={liveEdgePrice ? liveEdgePrice : price}
                        type={"fixed"}
                      />
                    </h2>
                    <h2
                      className={`${!isMobileOrTablet && "ml-4"} product-price`}
                    >
                      {pathnameIncludes("live-edge") ? (
                        <InStock sku={sku} isLoggedIn={customer} base={base} />
                      ) : (
                        inStockLogic
                      )}
                    </h2>
                  </div>
                  <div className="buttons-wrapper">
                    {isOptionStateEmpty ? null : (
                      <div
                        className="build-summary"
                        onClick={() => setBuildSummary(!buildSummary)}
                        onMouseEnter={() => setBuildHoverIcon(true)}
                        onMouseLeave={() => setBuildHoverIcon(false)}
                      >
                        <Icon
                          icon={
                            !isMobileOrTablet && buildHoverIcon
                              ? "buildSummaryHover"
                              : "buildSummary"
                          }
                        />
                        {!isMobileOrTablet ? (
                          <p className="link">Build Summary</p>
                        ) : null}
                      </div>
                    )}
                    <div className="action-buttons">
                      {!isAddingToCartForbidden && <div
                        className={"quote-request-button toolbar-button"}
                        onClick={() =>
                          isFinishReqDone
                            ? quoteRequestButton(
                                isCustom,
                                generate3DImage,
                                player,
                                uploadQuoteConfiguratorImage,
                                name,
                                isEdit,
                                product,
                                addItemToCart,
                                sku,
                                calculateOptions,
                                updateItemOnCart,
                                top,
                                base,
                                concatToString,
                                numOfBases
                              )
                            : setFinishError(true)
                        }
                      >
                        <Icon icon={"requestQuoteWhite"} /> Add to Quote Request
                      </div>}
                    </div>
                    {window.location.pathname.includes("live-edge") ? (
                      <></>
                    ) : (
                      <div
                        className={`favorite-button toolbar-button ${
                          isInFavorites ? "full" : "empty"
                        }`}
                        onClick={() => {
                          setShowModal(false);
                          let productWithOptions = { ...product };
                          productWithOptions.options = calculateOptions();
                          beforeAddToFavorites(productWithOptions);
                          addToWishlistMethod(productWithOptions);
                        }}
                      >
                        {isInFavorites ? (
                          <Icon icon={"heartFull"} />
                        ) : (
                          <Icon icon={"heartGray"} />
                        )}
                      </div>
                    )}
                  </div>
                  <div className={"downloads"}>
                    <Downloads
                      id={id}
                      name={name}
                      price={liveEdgePrice ? liveEdgePrice : price}
                      customizationOptionsQueryParameters={downloadTearSheet}
                      isCustom={isCustom}
                      player={player}
                      image={image}
                      sku={sku}
                      product={product}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2
                    className={`${!isMobileOrTablet && "ml-4"} product-price`}
                  >
                    {pathnameIncludes("live-edge") ? (
                      <InStock sku={sku} isLoggedIn={customer} base={base} />
                    ) : (
                      inStockLogic
                    )}
                  </h2>
                  <div
                    className={`buttons-wrapper ${isMobileOrTablet && "mt-5"}`}
                  >
                    {isOptionStateEmpty ? null : (
                      <div
                        className="build-summary"
                        onClick={() => setBuildSummary(!buildSummary)}
                        onMouseEnter={() => setBuildHoverIcon(true)}
                        onMouseLeave={() => setBuildHoverIcon(false)}
                      >
                        <Icon
                          icon={
                            !isMobileOrTablet && buildHoverIcon
                              ? "buildSummaryHover"
                              : "buildSummary"
                          }
                        />
                        {!isMobileOrTablet ? (
                          <p className="link">Build Summary</p>
                        ) : null}
                      </div>
                    )}
                    <div className="action-buttons">
                      {!isAddingToCartForbidden && <div
                        className={"quote-request-button toolbar-button"}
                        onClick={() => handleShowModal()}
                      >
                        Login Or Register
                      </div>}
                    </div>
                    {window.location.pathname.includes("live-edge") ? null : (
                      <div
                        className={"favorite-button toolbar-button"}
                        onClick={favoritesMethod}
                      >
                        <Icon icon={"heartGray"} />
                      </div>
                    )}
                  </div>
                  <div className={"downloads"}>
                    <Downloads
                      id={id}
                      name={name}
                      price={liveEdgePrice ? liveEdgePrice : price}
                      image={image}
                      customizationOptionsQueryParameters={downloadTearSheet}
                      isCustom={isCustom}
                      player={player}
                      product={product}
                      sku={sku}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {buildSummary ? (
        <Summary
          options={mappedOptions}
          setBuildSummary={setBuildSummary}
          buildSummary={buildSummary}
          optionState={optionState}
          size={{
            width: product?.width,
            height: product?.height,
            depth: product?.depth,
          }}
        />
      ) : null}
    </>
  );
};

export default Toolbar;
