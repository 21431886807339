import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBLink,
  MDBTypography,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import MenuItem from "../menuItem";
import DropdownItems from "../dropdownItems";
import Icon from "app/assets/icon/icon";
import { useLocation } from "react-router-dom";

const TopMenu = ({
  items,
  setActiveTopMenu,
  customOptionsMenu,
  subItems,
  activeMenu,
  activeTopMenu,
}) => {
  const location = useLocation();

  // TODO refactor this without hardcoded values
  const filteredItems = useMemo(() => {
    let activeItems = JSON.parse(JSON.stringify(items));
    const productsIndex = activeItems?.findIndex(
      (item) => item?.name?.toLowerCase() === "products"
    );
    if (!activeMenu || productsIndex === -1) {
      return activeItems;
    }

    let newProductChildren = activeItems[productsIndex]?.children?.filter(
      (item) =>
        !["COMMERCIAL", "RESIDENTIAL"]?.includes(item?.name?.toUpperCase())
    );
    const activeChild = items[productsIndex]?.children?.find(
      (item) => item?.name?.toUpperCase() === activeMenu
    );
    if (activeChild?.children?.length) {
      newProductChildren = [...newProductChildren, ...activeChild?.children];
    }
    activeItems[productsIndex].children = newProductChildren;
    return activeItems;
  }, [items, activeMenu]);

  //I couldn`t find a better way to close the dropdown menu when the user clicks on a link
  // PS. bootstrap is awful

  const [isMenuItemClicked, setIsMenuItemClicked] = useState(false);

  useEffect(() => {
    const dropdownMenu = document.querySelector(
      ".menu-top-item .dropdown-menu.show"
    );
    if (dropdownMenu) {
      dropdownMenu.classList.remove("show");
    }
  }, [isMenuItemClicked, location.pathname]);

  const [hoveredItem, setHoveredItem] = useState(null);

  const getDisabledLink = useCallback((urlPath) => {
    return [
      "products",
      "collections",
      "fabrics-finishes",
      "what-s-new",
      "company-story",
      "sales-representative",
      "where-to-buy",
      "coming-soon"
    ].some((path) => urlPath.includes(path))
      ? false
      : true;
  }, []);


  const menuRender = useMemo(
    () => (
      <>
        {filteredItems.map((item, index) => {
          if (!item?.include_in_menu) {
            return <></>;
          }

          // if (item?.name === "Collections") {
          //   return (
          //     <div className="h-100" key={`collection-menu-top-item-${index}`}>
          //       <MDBLink
          //         className={`menu-top-item cursor-pointer h-100 d-flex align-items-center py-0`}
          //         to={`/${item.url_path}`}
          //         onMouseEnter={() => {
          //           setActiveTopMenu(index);
          //         }}
          //         onClick={() => {
          //           setActiveTopMenu(false);
          //         }}
          //       >
          //         <MDBTypography
          //           tag="h2"
          //           variant="display-4"
          //           className="text-uppercase py-2 text-white"
          //         >
          //           {item.name}
          //         </MDBTypography>
          //       </MDBLink>
          //     </div>
          //   );
          // }
          return (
            <div
              key={`menu-top-item-${index}`}
              className="h-100"
              // onMouseEnter={() => {
              //   setActiveTopMenu(false);
              // }}
              // onMouseLeave={() => {
              //   setActiveTopMenu(false);
              // }}
            >
              <MDBDropdown
                className={`menu-top-item cursor-pointer h-100 d-flex align-items-center py-0`}

                // onMouseEnter={() => {
                //   setActiveTopMenu(index);
                // }}
                // onMouseLeave={() => {
                //   setActiveTopMenu(false);
                // }}
                // onClick={() => {
                //   setActiveTopMenu(false);
                // }}
              >
                <MDBLink
                  to={`/${item.url_path}`}
                  disabled={getDisabledLink(item.url_path)}
                  className="menu-top-item"
                >
                  <MDBDropdownToggle>
                    <MDBTypography
                      tag="h2"
                      variant="display-4"
                      className="text-uppercase py-2 text-white"
                    >
                      {item.name}
                    </MDBTypography>
                  </MDBDropdownToggle>
                </MDBLink>
                {!!item?.children?.length && (
                  <div className="dropdown-menu" responsive="end">
                    {item?.children?.map((child, index) => {
                      const isIncludedInMenu = child?.children?.some(
                        (grandChild) => {
                          return grandChild.include_in_menu === 1;
                        }
                      );

                      const shouldDisableLink =
                        child.url_path ===
                          "products/residential/shop-by-room" ||
                        child.url_path === "collections/featured-collections";

                      return (
                        !!child?.include_in_menu && (
                          <MDBDropdownItem
                            className="dropdown-parent"
                            key={`${child.name}-${index}`}
                            onClick={() => {
                              setIsMenuItemClicked((prevState) => !prevState);
                            }}
                            onMouseEnter={() => {
                              setHoveredItem(child);
                            }}
                            onMouseLeave={() => {
                              setHoveredItem(null);
                            }}
                          >
                            <MDBLink
                              to={`/${
                                child.name === "Residential" ||
                                child.name === "Commercial"
                                  ? child.name.toLowerCase()
                                  : child.url_path
                              }`}
                              disabled={shouldDisableLink}
                            >
                              {child.name}
                              {!!child?.children?.length &&
                                !!isIncludedInMenu && (
                                  <Icon icon="menuDropdownArrow" />
                                )}
                            </MDBLink>

                            <DropdownItems
                              hoveredItem={hoveredItem}
                              items={child.children}
                            />
                          </MDBDropdownItem>
                        )
                      );
                    })}
                  </div>
                )}
              </MDBDropdown>
            </div>
          );
        })}
        <MDBRow
          className={`position-absolute bg-white custom-options-menu ${
            (customOptionsMenu && subItems.length !== 0 && "show") || "hide"
          }`}
          onMouseLeave={() => {
            setActiveTopMenu(false);
          }}
        >
          <MDBCol className={`custom-options-content`}>
            <MDBRow>
              {subItems.map((item, index) => {
                return (
                  <MDBCol size="12" key={`custom-options-menu-${index}`}>
                    <MDBLink
                      className={`cursor-pointer`}
                      to={`/${item.url_path}`}
                      onClick={() => {
                        setActiveTopMenu(false);
                      }}
                    >
                      <MDBTypography tag="h2" variant="display-4">
                        {item.name}
                      </MDBTypography>
                    </MDBLink>
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    ),
    [filteredItems]
  );
  return (
    <>
      <MDBContainer
        className={`menu-top-container d-flex align-items-center justify-content-center`}
      >
        <MDBRow className={`h-100`}>
          <MDBCol
            className={`d-flex align-items-center justify-content-center bg-gray-darken-3`}
          >
            {menuRender}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default TopMenu;
