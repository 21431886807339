import React, { memo } from "react";
import isEqual from "lodash/isEqual";
import { MDBContainer, MDBRow } from "mdbreact";
import Image from "app/layout/image";
import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";
import CmsBlock from "app/layout/cmsBlock";

const HomepageVideo = memo(
  (props) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });

    return !isTabletOrMobile ? (
      // <div className="homepage-video">
      //   <video
      //     preload={`auto`}
      //     id={`homepage-video`}
      //     src="https://fairfieldm2.joladev.com/media/videos/tmp/video/fairfield-chair-homepage.mp4"
      //     autoPlay
      //     loop
      //     muted
      //     alt={`homepage-video`}
      //     width="100%"
      //     type="video/mp4"
      //     playsInline
      //   />
      //   <div className="info-holder">
      //     <h1 className="text-center display-1 mb-10 h-title">
      //       FURNITURE DESIGNED FOR LIFE AND CRAFTED WITH CARE, SINCE 1921.
      //     </h1>
      //   </div>
      // </div>
      <MDBContainer className="m-0 hero-banner" fluid>
      <MDBRow className="justify-content-center">
        <CmsBlock id="homepage_v2_hero" />
      </MDBRow>
    </MDBContainer>
    ) : (
      <MDBContainer className="m-0 hero-banner" fluid>
        <MDBRow className="justify-content-center">
          <CmsBlock id="homepage_v2_hero" />
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default HomepageVideo;
