import { useBoolean } from "ahooks";
import Icon from "app/assets/icon/icon";
import { Aggregation, Maybe, SortEnum } from "app/generated/graphql";
import Label from "app/layout/Label";
import Modal from "app/layout/Modal";
import ModalHeader from "app/layout/ModalHeader";
import PriceSlider from "app/layout/PriceSlider/PriceSlider";
import Select from "app/layout/Select";
import Switch from "app/layout/Switch";
import { createEnterHandler } from "app/utils/utils";
import { pathnameIncludes } from "app/utils/utils";
import PerPage from "app/layout/Pagination/PerPage";
import Button from "core/components/button";

import Accordion from "../../../../../app/layout/Accordion";

import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTypography,
  MDBCollapse,
} from "mdbreact";
import React, { useCallback, useMemo } from "react";
import {
  DragTarget,
  eventToPercentage,
  maxPercentage,
  minimumPercentageRange,
  minPercentage,
  percentageToPrice,
} from "../utils/price";
import {
  appendCountToMaybeOptionsLabel,
  Filters,
  getAggregation,
  getHasAggregation,
  getMultiValue,
  getNewFilterHasAggregation,
  getSortFromOption,
  getSortOptions,
  IFilter,
  IOption,
  mapAggregationOptions,
  mapGradeAggregationOptions,
  mapHexclrgrpAggregationOptions,
  mapFabricFinishTypeAggregationOptions,
  mapPatternAggregationOptions,
  Options,
  Sort,
  SortOption,
} from "../utils/utils";
import FilterItem from "./components/FilterItem";
import FilterList from "./components/FilterList";
import ModalHeaderBack from "./components/ModalHeaderBack";
import SelectPrice from "./components/SelectPrice";
import SwitchFilters, { SwitchFiltersProps } from "./components/SwitchFilters";
// import { useMediaQuery } from "react-responsive";
import { useLocation, useHistory } from "react-router-dom";
import SearchField from "./components/searchField";
import SelectQuantity from "./components/SelectQuantity";
import SelectDimension from "./components/SelectDimension";

const initialDrawerWrapperHeight = 100;

type MultiOptions = IOption | Options | null;
export interface FilterProps
  extends Pick<
    SwitchFiltersProps,
    "filter" | "setLaneFilter" | "clearLaneFilter" | "clearFilter"
  > {
  setFabricsCollectionFilter: (
    filter: IFilter["ffifabrics_collection"]
  ) => void;
  setGradeFilter: (filter: IFilter["ffifabrics_grade"]) => void;
  setPatternFilter: (filter: IFilter["ffifabrics_category1"]) => void;
  setHexclrgrpFilter: (filter: IFilter["ffifabrics_hexclrgrp"]) => void;
  setFabricsFinishTypeFilter: (
    filter: IFilter["ffifabrics_finishtype"]
  ) => void;
  setPriceFilter: (price: IFilter["price"]) => void;
  priceAggregation: Maybe<Aggregation>;
  minPrice: number;
  maxPrice: number;
  fromPercentage: number;
  toPercentage: number;
  setFromPercentage: (percentage: number) => void;
  setToPercentage: (percentage: number) => void;
  inFabricsContext: boolean;
  aggregations: Array<Maybe<Aggregation>>;
  gradeOptions: Options;
  patternOptions: Options;
  hexclrgrpOptions: Options;
  setCollectionFilter: (value: string[]) => void;
  setCategoryFilter: (value: any) => void;
  setCustomTypeFilter: (value: string[]) => void;
  setProductFeatureFilters: (value: string[]) => void;
  setProductTypeFilters: (value: string[]) => void;
  toggleIsNewFilter: () => void;
  toggleInStockFilter: () => void;
  toogleReadyToShip: () => void;
  toggleAssemblyFilter: () => void;
  toggleLeatherAvailableFilter: () => void;
  isLoggedIn: boolean;
  sort: Sort;
  setSort: (sort: Sort) => void;
  toggleIsAsc: () => void;
  isCollection: boolean;
  listingProps: any;
  categoryListDropdown: any;
  categoryId: any;
  isSearchPage: any;
  preselectedIndustry: any;
  maxSqFt: number;
  minSqFt: number;
  toSqFtPercentage: number;
  fromSqFtPercentage: number;
  sqFtAggregation: Maybe<Aggregation>;
  setToSqFtPercentage: (percentage: number) => void;
  setFromSqFtPercentage: (percentage: number) => void;
  setSquareFeetFilter: (
    price: IFilter["ffifabrics_avail_filter_bucket"]
  ) => void;
  sizeSuffix: string;
  searchVal: string;
  setSearchVal: (value: string) => void;
  setCurrentPage: (value: number) => void;
  isExact: boolean;
  setExact: (value: boolean) => void;
  categoryName: string;
  isInStock: boolean;
  setQuantityFilter: (
    ffi_qty_filter_bucket: IFilter["ffi_qty_filter_bucket"]
  ) => void;
  setWidthFilter: (
    ffi_width_filter_bucket: IFilter["ffi_width_filter_bucket"]
  ) => void;
  setHeightFilter: (
    ffi_height_filter_bucket: IFilter["ffi_height_filter_bucket"]
  ) => void;
  setDepthFilter: (
    ffi_depth_filter_bucket: IFilter["ffi_depth_filter_bucket"]
  ) => void;
  categoryIds: any[];
  setFinishCategory: any;
  finishCategory: any;
  paginationProps: any;
  setFilterModalOpen: any;
  filterModalOpen: any;
  isMobile: boolean;
  isTopFilter: boolean;
  handleFilterOpen: () => void;
  applyAllFilters: any;
  appliedFilter: any;
}
const Filter: React.FC<FilterProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [hasNewChangeWithUrl, setHasNewChangeWithUrl] = React.useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useBoolean(true);
  const [industryFilter, setIndustryFilter] = React.useState({});
  //value of search field before user presses search
  const [unconfirmedSearchVal, setUnconfirmedSearchVal] = React.useState(
    props.searchVal
  );

  const { isTopFilter, isMobile } = props;
  const handleSearchBtn = React.useCallback(
    () => props.setSearchVal(unconfirmedSearchVal),
    [props, unconfirmedSearchVal]
  );

  // const [selectedCategory, setSelectedCategory] = React.useState<
  //   | null
  //   | number
  //   | {
  //       value: number;
  //       label: string;
  //     }
  // >(null);

  // React.useEffect(() => {
  //         console.log(props.categoryListDropdown)
  //     console.log(props?.filter)
  //   // @ts-ignore
  //   if (props?.filter?.category_id_product) {

  //     const findCategory = props.categoryListDropdown?.find(
  //       // @ts-ignore
  //       (category) => category?.value === props?.filter?.category_id_product
  //     );
  //     console.log(findCategory)
  //     setSelectedCategory(findCategory);
  //     handleCategoryChange(findCategory);
  //   }
  //   // @ts-ignore
  // }, [props?.filter?.category_id_product, props.categoryListDropdown]);
  const selectedCategory = React.useMemo(() => {
    let found = props?.categoryListDropdown?.find(
      (option: any) => option.value === Number(props.filter.category_id_product)
    );
    return found;
  }, [
    props.categoryListDropdown,
    props.filter.category_id_product,
    location.pathname,
  ]);

  let dimensionWidthFilter = useMemo(
    () => props.filter?.ffi_width_filter_bucket,
    [location.pathname, props.filter?.ffi_width_filter_bucket]
  );

  let dimensionHeightFilter = useMemo(
    () => props.filter?.ffi_height_filter_bucket,
    [location.pathname, props.filter?.ffi_height_filter_bucket]
  );

  let dimensionDepthFilter = useMemo(
    () => props.filter?.ffi_depth_filter_bucket,
    [location.pathname, props.filter?.ffi_depth_filter_bucket]
  );
  
  const [showFilters, setShowFilters] = React.useState(true);
  const [drawerWrapperHeight, setDrawerWrapperHeight] = React.useState(
    initialDrawerWrapperHeight
  );
  const [categoryOpened, setCategoryOpened] = React.useState(false);
  const [
    resetSelectCategoryOptions,
    setResetSelectCategoryOptions,
  ] = React.useState(false);

  const isInStockProducts = useMemo(() => {
    // @ts-ignore
    return props?.listingProps?.some((item) => item.in_stock_filter);
  }, [props?.listingProps]);

  const isNewSelected = useMemo(
    // @ts-ignore
    () => props?.listingProps?.every((item) => item.is_new),
    [props?.listingProps]
  );

  const showReadyToShip = useMemo(() => {
    let found = props.aggregations.some(
      (aggregation) => aggregation?.attribute_code === "ready_to_ship_filter"
    );
    return found && props.isInStock;
  }, [props.aggregations, props.isInStock]);

  const toggleReadyToShip = useCallback(() => {
    props.setQuantityFilter(null);
    props.toogleReadyToShip();
  }, [props.setQuantityFilter, props.toogleReadyToShip]);

  const drawerRef = React.useRef<HTMLDivElement | null>(null);

  // let isTablet = useMediaQuery({ minWidth: 1200 });

  React.useEffect(() => {
    // * when filter updates it may be because a new entry was added to a multi select field
    // * in which case the height may need to be increased to prevent an overflow which conflicts with the product list
    // * the additional height isn't taken into account initially because there is no practical limit to the number of selections
    // * and because it is not visually pleasing
    // * hence an optimal height is set initially and updates are performed dynamically
    if (drawerRef.current) {
      const drawerHeight = drawerRef.current.getBoundingClientRect().height;
      if (drawerHeight > initialDrawerWrapperHeight) {
        setDrawerWrapperHeight(drawerHeight);
      }
    }
  }, [props.filter]);

  React.useEffect(() => {
    let industryFilter = props.aggregations?.find(
      (aggregation) => aggregation?.attribute_code === "industry_filter"
    );
    // @ts-ignore
    setIndustryFilter(industryFilter);
    // return () => {
    //   resetFilters();
    //   window.localStorage.removeItem("industry");
    // };
  }, [props.aggregations]);

  const toggleIsOpen = React.useCallback(() => setIsDrawerOpen.toggle(), [
    setIsDrawerOpen,
  ]);
  const sortOptions = React.useMemo(
    () => getSortOptions(props.isLoggedIn, location),
    [props.isLoggedIn, location]
  );

  const sortValue = React.useMemo(
    () =>
      sortOptions.find((option) => {
        const [key, direction] = option.value.split(",");
        return key === props.sort.key && direction === props.sort.direction;
      }),
    [sortOptions, props.sort]
  );

  const handleSortChange = React.useCallback(
    (option: SortOption) => {
      const [key, direction] = option.value.split(",");
      props.setSort({ key, direction } as Sort);
    },
    [props]
  );

  const [sortModalOpen, setSortModalOpen] = useBoolean();
  // const [filterModalOpen, setFilterModalOpen] = useBoolean(false);
  const [selectedFilter, setSelectedFilter] = React.useState(Filters.none);

  const selectLane = React.useCallback(() => {
    setSelectedFilter(Filters.lane);
  }, [setSelectedFilter]);
  const selectCollection = React.useCallback(() => {
    setSelectedFilter(Filters.ffi_collection);
  }, [setSelectedFilter]);
  const selectCustomType = React.useCallback(() => {
    setSelectedFilter(Filters.fficustopt_type_filterable);
  }, [setSelectedFilter]);
  const selectFabricsCollection = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_collection);
  }, [setSelectedFilter]);
  const selectGrade = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_grade);
  }, [setSelectedFilter]);
  const selectPattern = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_category1);
  }, [setSelectedFilter]);
  const selectHexclrgrp = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_hexclrgrp);
  }, [setSelectedFilter]);
  const selectPrice = React.useCallback(() => {
    setSelectedFilter(Filters.price);
  }, [setSelectedFilter]);
  const selectStockAmount = React.useCallback(() => {
    setSelectedFilter(Filters.ffifabrics_avail_filter_bucket);
  }, [setSelectedFilter]);
  const selectQtyAmount = React.useCallback(() => {
    setSelectedFilter(Filters.ffi_qty_filter_bucket);
  }, [setSelectedFilter]);
  const selectDimensions = React.useCallback(
    () => setSelectedFilter(Filters.dimensions),
    [setSelectedFilter]
  );
  const selectProductFeatures = React.useCallback(() => {
    setSelectedFilter(Filters.product_features);
  }, [setSelectedFilter]);

  const selectProductTypes = React.useCallback(() => {
    setSelectedFilter(Filters.ffi_product_type_filter);
  }, [setSelectedFilter]);
  const handleBack = React.useCallback(
    () => setSelectedFilter(Filters.none),
    []
  );

  const isAnyFilterActive = (filters: Object) => {
    let atLeastOneActiveFilter = false;
    const filtersValues = Object.values(filters);

    // @ts-ignore
    if (filters?.category_id_product) return true;

    filtersValues.forEach((element) => {
      switch (typeof element) {
        case "string":
          if (element !== "") {
            atLeastOneActiveFilter = true;
          }
          break;
        case "boolean":
          if (element !== false) {
            atLeastOneActiveFilter = true;
          }
          break;
        case "object":
          if (
            element !== null &&
            (element.length || Object.keys(element).length)
          ) {
            atLeastOneActiveFilter = true;
          }
          break;
        default:
          atLeastOneActiveFilter = false;
          break;
      }
    });

    return atLeastOneActiveFilter;
  };
  const handleSaveSelection = React.useCallback(
    () => props.setFilterModalOpen.setFalse(),
    [props.setFilterModalOpen]
  );

  const checkForDimensions = useCallback(
    (code) => props.aggregations?.find((item) => item?.attribute_code === code),
    [props.aggregations]
  );


  const widthDimensionCheck = useMemo(() => checkForDimensions("ffi_width_filter_bucket"), [checkForDimensions]);
  const heightDimensionCheck = useMemo(() => checkForDimensions("ffi_height_filter_bucket"), [checkForDimensions]);
  const depthDimensionCheck = useMemo(() => checkForDimensions("ffi_depth_filter_bucket"), [checkForDimensions]);

  const showDimensionFilters = useMemo(() => !pathnameIncludes("fabrics") &&
    (widthDimensionCheck ||
      heightDimensionCheck ||
      depthDimensionCheck), [widthDimensionCheck, heightDimensionCheck, depthDimensionCheck]);

  const handleMultiChange = React.useCallback(
    (setter: (options: IOption["value"][]) => void) => (
      options: MultiOptions
    ) => {
      setter(
        options === null
          ? []
          : Array.isArray(options)
          ? options.find((option) => !option.value)
            ? []
            : options.map((option) => option.value)
          : options.value
          ? [options.value]
          : []
      );
    },
    []
  );
  const [prevCollectionOptions, setPrevCollectionOptions] = React.useState(
    () => {
      return mapAggregationOptions(
        "ffi_collection",
        appendCountToMaybeOptionsLabel,
        props.aggregations
      )[1];
    }
  );
  const [hasCollectionOptions, collectionOptions] = React.useMemo(() => {
    return mapAggregationOptions(
      "ffi_collection",
      appendCountToMaybeOptionsLabel,
      props.aggregations
    );
  }, [props.aggregations]);

  const [hasProductFeatures, productFeaturesOptions] = React.useMemo(() => {
    return mapAggregationOptions(
      "product_features",
      appendCountToMaybeOptionsLabel,
      props.aggregations
    );
  }, [props.aggregations]);

  const productFeaturesValue = React.useMemo(() => {
    return getMultiValue(productFeaturesOptions, props.filter.product_features);
  }, [productFeaturesOptions, props, location.pathname]);

  const [hasProductTypes, productTypesOptions] = React.useMemo(() => {
    return mapAggregationOptions(
      "ffi_product_type_filter",
      appendCountToMaybeOptionsLabel,
      props.aggregations
    );
  }, [props.aggregations]);

  const productTypesValue = React.useMemo(() => {
    return getMultiValue(productTypesOptions, props.filter.ffi_product_type_filter);
  }, [productTypesOptions, props, location.pathname]);

  const collectionValue = React.useMemo(() => {
    return getMultiValue(collectionOptions, props.filter.ffi_collection);
  }, [collectionOptions, props, location.pathname]);

  const handleCollectionChange: any = React.useCallback(
    handleMultiChange(props.setCollectionFilter),
    []
  );

  const [selectedValues, setSelectedValues] = React.useState([
    { value: "", label: "All" },
  ]);

  // @ts-ignore
  const hasNew: number = React.useMemo(() => {
    if (hasNewChangeWithUrl < 2) {
      setHasNewChangeWithUrl(getNewFilterHasAggregation(props.aggregations));
      return getNewFilterHasAggregation(props.aggregations);
    }
  }, [props.aggregations]);

  const hasRetail: boolean = React.useMemo(
    () => getHasAggregation("ffi_laners", props.aggregations),
    [props.aggregations]
  );
  const hasSenior: boolean = React.useMemo(
    () => getHasAggregation("ffi_lanesl", props.aggregations),
    [props.aggregations]
  );
  const hasContract: boolean = React.useMemo(
    () => getHasAggregation("ffi_lanect", props.aggregations),
    [props.aggregations]
  );

  const [
    hasFabricsCollectionOptions,
    fabricsCollectionOptions,
  ] = React.useMemo(() => {
    return mapAggregationOptions(
      "ffifabrics_collection",
      appendCountToMaybeOptionsLabel,
      props.aggregations
    );
  }, [props.aggregations]);

  const fabricsCollectionValue = React.useMemo(
    () =>
      getMultiValue(
        fabricsCollectionOptions,
        props.filter.ffifabrics_collection
      ),
    [fabricsCollectionOptions, props.filter.ffifabrics_collection]
  );
  const handleFabricsCollectionChange = React.useCallback(
    handleMultiChange(props.setFabricsCollectionFilter),
    []
  );
  const handleCategoryChange = React.useCallback(props.setCategoryFilter, []);
  const [hasGradeOptions, gradeOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_grade",
        mapGradeAggregationOptions,
        props.aggregations
      ),
    [props.aggregations]
  );

  const gradeValue = React.useMemo(
    () => getMultiValue(gradeOptions, props.filter.ffifabrics_grade),
    [gradeOptions, props.filter.ffifabrics_grade]
  );
  const handleGradeChange = React.useCallback(
    handleMultiChange(props.setGradeFilter),
    []
  );

  const [hasPatternOptions, patternOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_category1",
        mapPatternAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );

  const [patternFilter, setPatternFilter] = React.useState("");
  const filteredPatternOptions = React.useMemo(
    () =>
      patternOptions.filter(
        (option) =>
          !patternFilter ||
          (typeof option.label === "string" &&
            option.label.includes(patternFilter))
      ),
    [patternFilter, patternOptions]
  );
  const handlePatternFilterChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setPatternFilter(e.currentTarget.value);
    },
    []
  );
  const patternValue = React.useMemo(
    () => getMultiValue(patternOptions, props.filter.ffifabrics_category1),
    [patternOptions, props.filter.ffifabrics_category1]
  );
  const handlePatternChange = React.useCallback(
    handleMultiChange(props.setPatternFilter),
    []
  );

  const [hasCustomTypeOptions, customTypeOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "fficustopt_type_filterable",
        mapHexclrgrpAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );

  const customTypeValue = React.useMemo(
    () =>
      getMultiValue(customTypeOptions, props.filter.fficustopt_type_filterable),
    [customTypeOptions, props.filter.fficustopt_type_filterable]
  );

  const handleCustomTypeChange = React.useCallback(
    handleMultiChange(props.setCustomTypeFilter),
    []
  );

  const [hasHexclrgrpOptions, hexclrgrpOptions] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_hexclrgrp",
        mapHexclrgrpAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );
  const hexclrgrpValue = React.useMemo(
    () => getMultiValue(hexclrgrpOptions, props.filter.ffifabrics_hexclrgrp),
    [hexclrgrpOptions, props.filter.ffifabrics_hexclrgrp]
  );

  const handleHexclrgrpChange = React.useCallback(
    handleMultiChange(props.setHexclrgrpFilter),
    []
  );

  const [
    hasFabricFinishTypeOptions,
    fabricFinishTypeOptions,
  ] = React.useMemo(
    () =>
      mapAggregationOptions(
        "ffifabrics_finishtype",
        mapFabricFinishTypeAggregationOptions,
        props.aggregations
      ) ?? [],
    [props.aggregations]
  );
  const fabricFinishTypeValue = React.useMemo(
    () =>
      getMultiValue(
        fabricFinishTypeOptions,
        props.filter.ffifabrics_finishtype
      ),
    [fabricFinishTypeOptions, props.filter.ffifabrics_finishtype]
  );
  const handleFabricFinishTypeChange = React.useCallback(
    handleMultiChange(props.setFabricsFinishTypeFilter),
    []
  );

  const [isDragging, setIsDragging] = React.useState<DragTarget>(
    DragTarget.none
  );

  const [initFrom, setInitFrom] = React.useState(false);
  const [initTo, setInitTo] = React.useState(false);

  React.useEffect(() => {
    if (props?.filter?.price?.from !== undefined) setInitFrom(true);
  }, [props?.filter?.price?.from]);

  React.useEffect(() => {
    if (props?.filter?.price?.to !== undefined) setInitTo(true);
  }, [props?.filter?.price?.to]);

  const hasPriceOptions = React.useMemo(
    () =>
      !!props.priceAggregation?.options?.length &&
      props.isLoggedIn &&
      !location.pathname.includes("fabrics-finishes/") &&
      !location.pathname.includes("custom-options") &&
      !(props.isSearchPage && props.categoryName === "fabrics-and-leather"),
    [
      props.priceAggregation,
      props.isLoggedIn,
      props.isSearchPage,
      props.categoryName,
      location.pathname,
    ]
  );

  const fromPrice = React.useMemo(() => {
    if (!initFrom) return props?.filter?.price?.from;
    return percentageToPrice(props.maxPrice, props.fromPercentage);
  }, [props.maxPrice, props.fromPercentage]);

  const toPrice = React.useMemo(() => {
    if (!initTo) return props?.filter?.price?.to;
    return percentageToPrice(props.maxPrice, props.toPercentage);
  }, [props.maxPrice, props.toPercentage]);

  const hasSqFtOptions = React.useMemo(
    () => !!props.sqFtAggregation?.options?.length && props.isLoggedIn,
    [props.sqFtAggregation, props.isLoggedIn]
  );

  const hasQtyOptions = React.useMemo(
    () =>
      // true
      !!getAggregation("ffi_qty_filter_bucket", props.aggregations)?.options
        ?.length &&
      props.filter.ready_to_ship_filter &&
      props.isInStock,
    [props.aggregations, props.filter.ready_to_ship_filter, props.isInStock]
  );
  const fromSqFt = React.useMemo(
    () => percentageToPrice(props.maxSqFt, props.fromSqFtPercentage),
    [props.maxSqFt, props.fromSqFtPercentage]
  );
  const toSqFt = React.useMemo(
    () => percentageToPrice(props.maxSqFt, props.toSqFtPercentage),
    [props.maxSqFt, props.toSqFtPercentage]
  );

  const trackRef = React.useRef<HTMLDivElement | null>(null);
  const handleDragStartFrom = React.useCallback(
    (e) => setIsDragging(DragTarget.from),
    []
  );

  const resetFilters = React.useCallback(() => {
    // setSelectedCategory(null);
    props.clearFilter();
    setResetSelectCategoryOptions(true);
    setTimeout(() => {
      setResetSelectCategoryOptions(false);
    }, 0);
  }, []);

  // React.useEffect(() => {
  //   props.clearFilter();
  // }, [props, props.categoryId]);

  const handleDrag = React.useCallback(
    (e: any) => {
      if (trackRef.current) {
        if (isDragging === DragTarget.from) {
          props.setFromPercentage(
            eventToPercentage({
              minPercentage,
              maxPercentage: props.toPercentage - minimumPercentageRange,
              event: e,
              trackElement: trackRef.current,
            })
          );
        } else if (isDragging === DragTarget.to) {
          props.setToPercentage(
            eventToPercentage({
              minPercentage: props.fromPercentage + minimumPercentageRange,
              maxPercentage,
              event: e,
              trackElement: trackRef.current,
            })
          );
        }
      }
    },
    [isDragging, props]
  );

  const handleSqFtDrag = React.useCallback(
    (e: any) => {
      if (trackRef.current) {
        if (isDragging === DragTarget.from) {
          props.setFromSqFtPercentage(
            eventToPercentage({
              minPercentage,
              maxPercentage: props.toPercentage - minimumPercentageRange,
              event: e,
              trackElement: trackRef.current,
            })
          );
        } else if (isDragging === DragTarget.to) {
          props.setToSqFtPercentage(
            eventToPercentage({
              minPercentage: props.fromPercentage + minimumPercentageRange,
              maxPercentage,
              event: e,
              trackElement: trackRef.current,
            })
          );
        }
      }
    },
    [isDragging, props]
  );

  React.useEffect(() => {
    if (!selectedCategory) {
      setResetSelectCategoryOptions(true);
      setTimeout(() => {
        setResetSelectCategoryOptions(false);
      }, 0);
    }
  }, [selectedCategory]);

  const handleDragStartTo = React.useCallback(
    () => setIsDragging(DragTarget.to),
    []
  );

  const handleDragStop = React.useCallback(() => {
    setIsDragging(DragTarget.none);

    props.setPriceFilter({
      from: percentageToPrice(props.maxPrice, props.fromPercentage),
      to: percentageToPrice(props.maxPrice, props.toPercentage),
    });
  }, [setIsDragging, props]);

  const handleDragStartToSqFt = React.useCallback(
    () => setIsDragging(DragTarget.to),
    []
  );

  const handleDragStopSqFt = React.useCallback(() => {
    setIsDragging(DragTarget.none);

    props.setSquareFeetFilter({
      from: percentageToPrice(props.maxSqFt, props.fromSqFtPercentage),
      to: percentageToPrice(props.maxSqFt, props.toSqFtPercentage),
    });
  }, [setIsDragging, props]);

  const sqFtSliderProps = React.useMemo(() => {
    return {
      className: !props.isMobile ? "w-100" : "w-75",
      minPrice: props.minSqFt,
      maxPrice: props.maxSqFt,
      fromPrice:
        // @ts-ignore
        +props?.filter?.ffifabrics_avail_filter_bucket?.from ?? fromSqFt,
      // @ts-ignore
      toPrice: props?.filter?.ffifabrics_avail_filter_bucket?.to
        ? +props?.filter?.ffifabrics_avail_filter_bucket?.to
        : toSqFt,
      fromPercentage: props.fromSqFtPercentage,
      toPercentage: props.toSqFtPercentage,
      handleDragStartFrom: handleDragStartFrom,
      handleDragStartTo: handleDragStartTo,
      handleDrag: handleSqFtDrag,
      handleDragStop: handleDragStopSqFt,
    };
  }, [
    fromSqFt,
    toSqFt,
    handleSqFtDrag,
    handleDragStartFrom,
    handleDragStartTo,
    props.fromSqFtPercentage,
    props.toSqFtPercentage,
    props.maxSqFt,
    props.minSqFt,
    handleDragStopSqFt,
    props.filter?.ffifabrics_avail_filter_bucket,
    props.isMobile,
  ]);
  const sliderProps = React.useMemo(() => {
    return {
      className: !props.isMobile ? "w-100" : "w-75",
      minPrice: props.minPrice,
      maxPrice: props.maxPrice,
      // @ts-ignore
      fromPrice: fromPrice ?? +props?.filter?.price?.from,
      // @ts-ignore
      toPrice: toPrice ? toPrice : +props?.filter?.price?.to,
      fromPercentage:
        !isAnyFilterActive(props?.filter) && props.fromPercentage
          ? 0
          : props.fromPercentage,
      toPercentage:
        !isAnyFilterActive(props?.filter) && props.toPercentage < 100
          ? 100
          : props.toPercentage,
      handleDragStartFrom: handleDragStartFrom,
      handleDragStartTo: handleDragStartTo,
      handleDrag: handleDrag,
      handleDragStop: handleDragStop,
    };
  }, [
    fromPrice,
    toPrice,
    handleDrag,
    handleDragStartFrom,
    handleDragStartTo,
    props.fromPercentage,
    props.toPercentage,
    props.maxPrice,
    props.minPrice,
    handleDragStop,
    props.filter?.price,
    props.isMobile,
  ]);

  React.useEffect(() => {
    let filters = [
      hasCollectionOptions,
      hasFabricsCollectionOptions,
      hasGradeOptions,
      hasPatternOptions,
      hasHexclrgrpOptions,
      hasCustomTypeOptions,
      hasRetail,
      hasSenior,
      hasContract,
      productFeaturesValue,
      productTypesValue
    ];
    let hideFilters = filters.every((filter) => filter === false);
    if (hideFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [props]);

  React.useEffect(() => {
    return history.listen((location) => {
      setHasNewChangeWithUrl(1);
    });
  }, [history]);

  const clearDimensionFilter = (label: string, callable: any) => (
    <div
      className="d-flex align-items-center"
      onClick={() => callable(null)}
      onKeyUp={createEnterHandler(() => callable(null))}
      tabIndex={0}
      role="button"
    >
      <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
        {label}
        <Icon icon="x" />
      </MDBTypography>
    </div>
  );
  let whatsNew = window.location.href.indexOf("what-s-new") > -1;
  let isNotFabricsAvail = selectedFilter !== Filters.ffifabrics_avail_filter_bucket;

  return (
    <>
      <MDBRow fluid className={`${!isTopFilter && "sidebar-filter"} ${isTopFilter && "top-filter-sticky"}`}>
        <MDBCol fluid className="p-0">
          {isTopFilter && (
            <MDBRow className={"top-filter-wrapper"}>
              <MDBCol
                lg="6"
                size={(props.isMobile && "12") || "8"}
                className={`${
                  props.isMobile && "mb-4 justify-content-between"
                } d-flex align-items-center p-xs-0 pl-0  `}
              >
                {hasCollectionOptions ||
                hasFabricsCollectionOptions ||
                hasGradeOptions ||
                hasPatternOptions ||
                hasHexclrgrpOptions ||
                hasCustomTypeOptions ||
                hasRetail ||
                hasSenior ||
                hasContract ||
                hasSqFtOptions ||
                hasPriceOptions ? (
                  <>
                    {!showFilters ? null : (
                      <div
                        className="d-flex align-items-center"
                        onClick={props.handleFilterOpen}
                        onKeyUp={props.handleFilterOpen}
                        tabIndex={0}
                        role="button"
                      >
                        {props.filterModalOpen ? (
                          <Icon icon="filterOn" />
                        ) : (
                          <Icon icon="filterOff" />
                        )}
                        <MDBTypography className="ml-4 mb-0 fs-14 text-default">
                          {props.filterModalOpen
                            ? "Hide Filters"
                            : "Show Filters"}
                        </MDBTypography>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <>
                  {/* {props?.categoryIds?.length ? (
                  <Select
                    className="ml-4 pl-2 md"
                    options={props?.categoryIds}
                    value={props?.finishCategory}
                    // @ts-ignore
                    onChange={(category) => props?.setFinishCategory(category)}
                  />
                ) : (
                  <></>
                )} */}

                  {window.location.pathname.includes("/instock") ? (
                    <>
                      <div className="stock-wrapper">
                        <Switch
                          style={{ color: "#6C808B" }}
                          className="pl-xs-5 pl-4"
                          checked={!!props.filter.ffi_leatheravl}
                          onChange={props.toggleLeatherAvailableFilter}
                          label="Available in Leather"
                        />
                      </div>
                      <div className="stock-wrapper">
                        <Switch
                          style={{ color: "#6C808B" }}
                          className="pl-xs-5 pl-4"
                          checked={!!props.filter.ffi_assem_req}
                          onChange={props.toggleAssemblyFilter}
                          label="Assembly Required"
                        />
                      </div>
                    </>
                  ) : null}
                </>
              </MDBCol>

              <MDBCol
                size={(props.isMobile && "6") || "6"}
                className={`sort-products d-flex align-items-center justify-content-end  ${
                  hasCollectionOptions ||
                  hasFabricsCollectionOptions ||
                  hasGradeOptions ||
                  hasPatternOptions ||
                  hasHexclrgrpOptions ||
                  hasRetail ||
                  hasSenior ||
                  hasContract ||
                  hasCustomTypeOptions ||
                  hasPriceOptions
                    ? "has-filters"
                    : "no-filters"
                }`}
              >
                <div className="d-none d-md-flex align-items-center mr-4">
                  <PerPage {...props.paginationProps} />
                </div>

                <div className="d-none d-md-flex align-items-center">
                  <MDBTypography className="mb-0 text-gray-darken-3 fs-14">
                    Sort:
                  </MDBTypography>
                  <Select
                    className="ml-2 pl-2 md"
                    options={sortOptions}
                    value={sortValue}
                    onChange={handleSortChange}
                  />
                  <Icon
                    className="ml-2 mr-4 cursor-pointer"
                    icon={
                      props.sort.direction === SortEnum.Asc
                        ? "arrowUp"
                        : "arrowDown"
                    }
                    onClick={props.toggleIsAsc}
                  />
                </div>
                <MDBBtn
                  className="sortButton d-md-none my-0"
                  color="secondary"
                  onClick={setSortModalOpen.setTrue}
                >
                  Sort
                </MDBBtn>
                <MDBTypography className="d-flex body-6 my-5 py-md-5">
                  {props.paginationProps.totalCount} Item(s)
                </MDBTypography>
              </MDBCol>
              {(selectedCategory ||
                collectionValue.length ||
                dimensionWidthFilter ||
                dimensionDepthFilter ||
                dimensionHeightFilter ||
                fabricsCollectionValue.length ||
                customTypeValue.length ||
                patternValue.length ||
                gradeValue.length ||
                productFeaturesValue.length ||
                productTypesValue.length ||
                hexclrgrpValue.length ||
                !!props.filter.is_new) && (
                <MDBCol
                  className="d-flex align-items-center justify-content-start plp-tags-wrapper"
                  lg="12"
                >
                  <MDBContainer className="d-flex justify-content-start align-items-center p-0 m-0 flex-wrap">
                    {/*TODO: refactor, make it dynamic */}
                    {selectedCategory && (
                      <>
                        <div
                          className="d-flex align-items-center xl-mb-0 mb-3"
                          onClick={() => {
                            props.setCategoryFilter(null);
                            // setSelectedCategory(null);
                          }}
                          onKeyUp={createEnterHandler(() =>
                            props.setCategoryFilter(null)
                          )}
                          tabIndex={0}
                          role="button"
                        >
                          <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                            {/* @ts-ignore */}
                            {selectedCategory?.label}
                            <Icon icon="x" />
                          </MDBTypography>
                        </div>
                      </>
                    )}
                    {collectionValue.length &&
                    props.appliedFilter.ffi_collection.length ? (
                      <>
                        {collectionValue.map((val) => (
                          <div
                            key={val.value}
                            className="d-flex align-items-center xl-mb-0 mb-3"
                            onClick={() => props.setCollectionFilter(
                              collectionValue
                                ?.filter(
                                  (collection) =>
                                    collection.value !== val?.value
                                )
                                ?.map((filter) => filter?.value)
                            )}
                            onKeyUp={createEnterHandler(() =>
                              props.setCollectionFilter(
                                collectionValue
                                  ?.filter(
                                    (collection) =>
                                      collection.value !== val?.value
                                  )
                                  ?.map((filter) => filter?.value)
                              )
                            )}
                            tabIndex={0}
                            role="button"
                          >
                            <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                              {val.label}
                              <Icon icon="x" />
                            </MDBTypography>
                          </div>
                        ))}
                      </>
                    ) : null}
                    {productFeaturesValue.length > 0 && (
                      <>
                        {productFeaturesValue?.map((productFeature) => (
                          <div
                            className="d-flex align-items-center xl-mb-0 mb-3"
                            onClick={() =>
                              props.setProductFeatureFilters(
                                productFeaturesValue
                                  ?.filter(
                                    (productF) =>
                                      productF.value !== productFeature?.value
                                  )
                                  ?.map((filter) => filter?.value)
                              )
                            }
                            onKeyUp={createEnterHandler(() =>
                              props.setProductFeatureFilters(
                                productFeaturesValue
                                  ?.filter(
                                    (productF) =>
                                      productF.value !== productFeature?.value
                                  )
                                  ?.map((filter) => filter?.value)
                              )
                            )}
                            tabIndex={0}
                            role="button"
                          >
                            <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                              {productFeature?.label}
                              <Icon icon="x" />
                            </MDBTypography>
                          </div>
                        ))}
                      </>
                    )}
                    {productTypesValue.length > 0 && (
                      <>
                        {productTypesValue?.map((productType) => (
                          <div
                            className="d-flex align-items-center xl-mb-0 mb-3"
                            onClick={() =>
                              props.setProductTypeFilters(
                                productTypesValue
                                  ?.filter(
                                    (productT) =>
                                      productT.value !== productType?.value
                                  )
                                  ?.map((filter) => filter?.value)
                              )
                            }
                            onKeyUp={createEnterHandler(() =>
                              props.setProductTypeFilters(
                                productTypesValue
                                  ?.filter(
                                    (productT) =>
                                      productT.value !== productType?.value
                                  )
                                  ?.map((filter) => filter?.value)
                              )
                            )}
                            tabIndex={0}
                            role="button"
                          >
                            <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                              {productType?.label}
                              <Icon icon="x" />
                            </MDBTypography>
                          </div>
                        ))}
                      </>
                    )}
                    <div className="xl-mb-0 mb-3">
                      {dimensionWidthFilter &&
                        clearDimensionFilter(
                          "Dimension Width Filter",
                          props.setWidthFilter
                        )}
                    </div>
                    <div className="xl-mb-0 mb-3">
                      {dimensionHeightFilter &&
                        clearDimensionFilter(
                          "Dimension Height Filter",
                          props.setHeightFilter
                        )}
                    </div>
                    <div className="xl-mb-0 mb-3">
                      {dimensionDepthFilter &&
                        clearDimensionFilter(
                          "Dimension Depth Filter",
                          props.setDepthFilter
                        )}
                    </div>
                    {fabricsCollectionValue.length > 0 && (
                      fabricsCollectionValue?.map(farbicCollection => <>
                        <div
                          className="d-flex align-items-center xl-mb-0 mb-3"
                          onClick={() => props.setFabricsCollectionFilter(
                            fabricsCollectionValue
                              ?.filter(
                                (patternF) =>
                                  patternF.value !== farbicCollection?.value
                              )
                              ?.map((filter) => filter?.value)
                          )}
                          onKeyUp={createEnterHandler(() =>
                            props.setFabricsCollectionFilter(
                              fabricsCollectionValue
                                ?.filter(
                                  (patternF) =>
                                    patternF.value !== farbicCollection?.value
                                )
                                ?.map((filter) => filter?.value)
                            )
                          )}
                          tabIndex={0}
                          role="button"
                        >
                          <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                            {farbicCollection.label}
                            <Icon icon="x" />
                          </MDBTypography>
                        </div>
                      </>
                    ))}{" "}
                    {hexclrgrpValue.length > 0 && (
                      hexclrgrpValue?.map(hexclrValue => <>
                        <div
                          className="d-flex align-items-center xl-mb-0 mb-3"
                          onClick={() => props.setHexclrgrpFilter(
                            hexclrgrpValue
                              ?.filter(
                                (patternF) =>
                                  patternF.value !== hexclrValue?.value
                              )
                              ?.map((filter) => filter?.value)
                          )}
                          onKeyUp={createEnterHandler(() =>
                            props.setHexclrgrpFilter(
                              hexclrgrpValue
                                ?.filter(
                                  (patternF) =>
                                    patternF.value !== hexclrValue?.value
                                )
                                ?.map((filter) => filter?.value)
                            )
                          )}
                          tabIndex={0}
                          role="button"
                        >
                          <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                            {hexclrValue.label}
                            <Icon icon="x" />
                          </MDBTypography>
                        </div>
                      </>)
                    )}{" "}
                    {customTypeValue.length > 0 && (
                      customTypeValue?.map(customType => <>
                        <div
                          className="d-flex align-items-center xl-mb-0 mb-3"
                          onClick={() => props.setCustomTypeFilter(
                            customTypeValue
                              ?.filter(
                                (patternF) =>
                                  patternF.value !== customType?.value
                              )
                              ?.map((filter) => filter?.value)
                          )}
                          onKeyUp={createEnterHandler(() =>
                            props.setCustomTypeFilter(
                              customTypeValue
                                ?.filter(
                                  (patternF) =>
                                    patternF.value !== customType?.value
                                )
                                ?.map((filter) => filter?.value)
                            )
                          )}
                          tabIndex={0}
                          role="button"
                        >
                          <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                            {customType?.label}
                            <Icon icon="x" />
                          </MDBTypography>
                        </div>
                      </>
                    ))}
                    {patternValue.length > 0 && (
                      patternValue?.map(patternV => <>
                        <div
                          className="d-flex align-items-center xl-mb-0 mb-3"
                          onClick={() => props.setPatternFilter(
                            patternValue
                              ?.filter(
                                (patternF) =>
                                  patternF.value !== patternV?.value
                              )
                              ?.map((filter) => filter?.value)
                          )}
                          onKeyUp={createEnterHandler(() =>
                            props.setPatternFilter(
                              patternValue
                                ?.filter(
                                  (patternF) =>
                                    patternF.value !== patternV?.value
                                )
                                ?.map((filter) => filter?.value)
                            )
                          )}
                          tabIndex={0}
                          role="button"
                        >
                          <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                            {patternV.label}
                            <Icon icon="x" />
                          </MDBTypography>
                        </div>
                      </>)
                    )}{" "}
                    {gradeValue.length > 0 && (
                      <>
                        {gradeValue?.map(gradeFilter => <div className="flex-grow-0 d-flex justify-content-end xl-mb-0 mb-3">
                          <div
                            className="d-flex align-items-center"
                            onClick={() => props.setGradeFilter(
                              gradeValue
                                ?.filter(
                                  (grade) =>
                                    grade.value !== gradeFilter?.value
                                )
                                ?.map((filter) => filter?.value)
                            )}
                            onKeyUp={createEnterHandler(() =>
                              props.setGradeFilter(
                                gradeValue
                                  ?.filter(
                                    (grade) =>
                                      grade.value !== gradeFilter?.value
                                  )
                                  ?.map((filter) => filter?.value)
                              )
                            )}
                            tabIndex={0}
                            role="button"
                          >
                            <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                              {gradeFilter?.label}
                              <Icon icon="x" />
                            </MDBTypography>
                          </div>
                        </div>)}
                      </>
                    )}
                    {!!props.filter.is_new && (
                      <>
                        <div className="flex-grow-0 d-flex justify-content-end xl-mb-0 mb-3">
                          <div
                            className="d-flex align-items-center"
                            onClick={props.toggleIsNewFilter}
                            onKeyUp={props.toggleIsNewFilter}
                            tabIndex={0}
                            role="button"
                          >
                            <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                              {"Only New Items"}
                              <Icon icon="x" />
                            </MDBTypography>
                          </div>
                        </div>
                      </>
                    )}
                    {!!props.filter.in_stock_filter && (
                      <>
                        <div className="flex-grow-0 d-flex justify-content-end xl-mb-0 mb-3">
                          <div
                            className="d-flex align-items-center"
                            onClick={props.toggleInStockFilter}
                            onKeyUp={props.toggleInStockFilter}
                            tabIndex={0}
                            role="button"
                          >
                            <MDBTypography className="mr-3 mb-0 text-default fs-14 clear-filters-plp">
                              {"Only In Stock"}
                              <Icon icon="x" />
                            </MDBTypography>
                          </div>
                        </div>
                      </>
                    )}
                  </MDBContainer>
                </MDBCol>
              )}
              {/* {props.isMobile && (
                <MDBCol
                  className="d-flex align-items-center justify-content-end mt-4"
                  size="12"
                >
                  {isAnyFilterActive(props.filter) && (
                    <div className="flex-grow-0 d-flex justify-content-end">
                      <div
                        className="d-flex align-items-center"
                        onClick={resetFilters}
                        onKeyUp={createEnterHandler(resetFilters)}
                        tabIndex={0}
                        role="button"
                        style={{ textDecoration: "underline" }}
                      >
                        <MDBTypography className="ml-3 mb-0 text-default fs-14 clear-filters-plp">
                          Clear Filters
                        </MDBTypography>
                      </div>
                    </div>
                  )}
                </MDBCol>
              )} */}
            </MDBRow>
          )}

          {!isTopFilter && !isMobile && (
            <MDBContainer fluid className="">
              {hasCollectionOptions ||
              hasFabricsCollectionOptions ||
              hasGradeOptions ||
              hasPatternOptions ||
              hasHexclrgrpOptions ||
              hasRetail ||
              hasSenior ||
              hasContract ||
              hasCustomTypeOptions ||
              hasPriceOptions ||
              hasProductTypes ||
              window.location.pathname.includes("/instock") ? (
                <>
                  <div className="search-wrapper">
                    <SearchField
                      isExact={props.isExact}
                      setExact={props.setExact}
                      unconfirmedSearchVal={unconfirmedSearchVal}
                      setUnconfirmedSearchVal={setUnconfirmedSearchVal}
                      handleSearchBtn={handleSearchBtn}
                      isSidebar={false}
                      setSearchVal={props.setSearchVal}
                      searchVal={props.searchVal}
                      // @ts-ignore
                      setCurrentPage={props.setCurrentPage}
                    />
                  </div>
                  {hasProductFeatures && (
                    <Accordion title="Product Features">
                      <FilterList
                        filterValue={props.filter.product_features}
                        options={productFeaturesOptions}
                        setFilterValue={props.setProductFeatureFilters}
                        onSaveSelection={handleSaveSelection}
                        sideFilter={true}
                        skipAllOption={true}
                      />
                      {/* <Select
                                placeholder="Collection"
                                className="md mr-4"
                                value={collectionValue}
                                options={collectionOptions}
                                onChange={handleCollectionChange}
                                isSearchable={true}
                              /> */}
                    </Accordion>
                  )}

                  <Accordion title="Quick Filter">
                    <div>
                      {(hasNewChangeWithUrl > 1 && !whatsNew) || isNewSelected ? (
                        <div
                          className={`d-flex align-items-center text-center plp-new-items-wrapper justify-content-end`}
                        >
                          <Switch
                            checked={!!props.filter.is_new}
                            onChange={props.toggleIsNewFilter}
                            label="New Items"
                            // disabled={hasNew <= 1}
                          />
                        </div>
                      ) : null}
                      {isInStockProducts &&
                      !window.location.pathname.includes("/instock") ? (
                        <div className="stock-wrapper">
                          <Switch
                            style={{ color: "#6C808B" }}
                            className="pl-xs-5"
                            checked={!!props.filter.in_stock_filter}
                            onChange={props.toggleInStockFilter}
                            label="In Stock"
                          />
                        </div>
                      ) : null}

                      {showReadyToShip ? (
                        <div className="stock-wrapper">
                          <Switch
                            style={{ color: "#6C808B" }}
                            className="pl-xs-5 pl-4"
                            checked={!!props.filter.ready_to_ship_filter}
                            onChange={toggleReadyToShip}
                            label="Ready to ship"
                          />
                        </div>
                      ) : null}
                    </div>
                  </Accordion>
                  {hasProductTypes && (
                    <Accordion title="Product Types">
                      <FilterList
                        filterValue={props.filter.ffi_product_type_filter}
                        options={productTypesOptions}
                        setFilterValue={props.setProductTypeFilters}
                        onSaveSelection={handleSaveSelection}
                        sideFilter={true}
                      />
                    </Accordion>
                  )}
                  {props.isCollection && (
                    <>
                      {showDimensionFilters && (
                        <Accordion title="Dimensions">
                          <div className="w-100">
                            {widthDimensionCheck && (
                              <SelectDimension
                                filter={props.filter}
                                aggregations={props.aggregations}
                                setDimensionFilter={props.setWidthFilter}
                                isMobile={true}
                                dimension="ffi_width_filter_bucket"
                              />
                            )}
                            {heightDimensionCheck && (
                              <SelectDimension
                                filter={props.filter}
                                aggregations={props.aggregations}
                                setDimensionFilter={props.setHeightFilter}
                                isMobile={true}
                                dimension="ffi_height_filter_bucket"
                              />
                            )}
                            {depthDimensionCheck && (
                              <SelectDimension
                                filter={props.filter}
                                aggregations={props.aggregations}
                                setDimensionFilter={props.setDepthFilter}
                                isMobile={true}
                                dimension="ffi_depth_filter_bucket"
                              />
                            )}
                          </div>
                        </Accordion>
                      )}
                      {hasPriceOptions && (
                        <Accordion title="Price">
                          <div className="w-100 pl-3 pr-3">
                            <div
                              className="mt-5 w-100 mb-3 d-flex justify-content-center"
                              onMouseUp={handleDragStop}
                              onMouseMove={handleDrag}
                              // @ts-ignore
                              onTouchMove={handleDrag}
                              // @ts-ignore
                              onTouchEnd={handleDragStop}
                              tabIndex={0}
                              role="button"
                            >
                              <PriceSlider ref={trackRef} {...sliderProps} />
                            </div>
                          </div>
                        </Accordion>
                      )}
                    </>
                  )}
                  {!props.isCollection ? (
                    <div
                      ref={drawerRef}
                      className={`content-wrapper w-100 d-flex align-items-center flex-column`}
                    >
                      {
                        <>
                          {(window.location.pathname.includes("/instock") ||
                            (props.isSearchPage &&
                              props.categoryListDropdown?.length > 0)) &&
                          !resetSelectCategoryOptions ? (
                            <Accordion title="Category">
                              <Select
                                placeholder="Category"
                                className="md mr-4"
                                options={props.categoryListDropdown}
                                // @ts-ignore
                                onChange={(e) => {
                                  // setSelectedCategory(e);
                                  handleCategoryChange(e);
                                }}
                                value={selectedCategory}
                                isSearchable={true}
                              />
                            </Accordion>
                          ) : null}

                          {hasCollectionOptions && (
                            <Accordion title="Collections">
                              <FilterList
                                filterValue={props.filter.ffi_collection}
                                options={collectionOptions}
                                setFilterValue={props.setCollectionFilter}
                                onSaveSelection={handleSaveSelection}
                                sideFilter={true}
                              />
                              {/* <Select
                                placeholder="Collection"
                                className="md mr-4"
                                value={collectionValue}
                                options={collectionOptions}
                                onChange={handleCollectionChange}
                                isSearchable={true}
                              /> */}
                            </Accordion>
                          )}

                          {(hasRetail || hasSenior || hasContract) &&
                            +!location.pathname.includes("/industry") && (
                              <SwitchFilters
                                hasRetail={hasRetail}
                                selectedValues={selectedValues}
                                setSelectedValues={setSelectedValues}
                                hasSenior={hasSenior}
                                hasContract={hasContract}
                                filter={props.filter}
                                clearFilter={resetFilters}
                                setLaneFilter={props.setLaneFilter}
                                clearLaneFilter={props.clearLaneFilter}
                                vertical={false}
                                listingProps={props.listingProps}
                                // @ts-ignore
                                industryFilter={industryFilter}
                                // className="mt-4"
                              />
                            )}

                          {showDimensionFilters && (
                              <Accordion title="Dimensions">
                                <div className="w-100">
                                  {widthDimensionCheck && (
                                    <SelectDimension
                                      filter={props.filter}
                                      aggregations={props.aggregations}
                                      setDimensionFilter={props.setWidthFilter}
                                      isMobile={true}
                                      dimension="ffi_width_filter_bucket"
                                    />
                                  )}
                                  {heightDimensionCheck && (
                                    <SelectDimension
                                      filter={props.filter}
                                      aggregations={props.aggregations}
                                      setDimensionFilter={props.setHeightFilter}
                                      isMobile={true}
                                      dimension="ffi_height_filter_bucket"
                                    />
                                  )}
                                  {depthDimensionCheck && (
                                    <SelectDimension
                                      filter={props.filter}
                                      aggregations={props.aggregations}
                                      setDimensionFilter={props.setDepthFilter}
                                      isMobile={true}
                                      dimension="ffi_depth_filter_bucket"
                                    />
                                  )}
                                </div>
                              </Accordion>
                            )}
                          {hasFabricsCollectionOptions && (
                            <Accordion title="Collection">
                              {/* <Select
                                // isMulti
                                // wrapperClass="multi"
                                placeholder="Collection"
                                className="md mr-4"
                                value={fabricsCollectionValue}
                                options={fabricsCollectionOptions}
                                onChange={handleFabricsCollectionChange}
                                isSearchable={true}
                              /> */}
                              <FilterList
                                filterValue={props.filter.ffifabrics_collection}
                                // @ts-ignore
                                options={fabricsCollectionOptions}
                                setFilterValue={props.setFabricsCollectionFilter}
                                onSaveSelection={handleSaveSelection}
                                sideFilter={true}
                              />
                            </Accordion>
                          )}

                          {(hasGradeOptions ||
                            hasPatternOptions ||
                            hasHexclrgrpOptions) && (
                            <>
                              {hasGradeOptions &&
                                (location.pathname.includes("/fabric") ||
                                  props.isSearchPage) && (
                                  <Accordion title="Grade">
                                    <FilterList
                                      filterValue={props.filter.ffifabrics_grade}
                                      options={gradeOptions}
                                      setFilterValue={props.setGradeFilter}
                                      onSaveSelection={handleSaveSelection}
                                      sideFilter={true}
                                    />
                                  </Accordion>
                                )}
                              {hasPatternOptions &&
                                location.pathname.includes("/fabric") && (
                                  <Accordion title="Pattern">
                                    <FilterList
                                      filterValue={
                                        props.filter.ffifabrics_category1
                                      }
                                      options={filteredPatternOptions}
                                      setFilterValue={props.setPatternFilter}
                                      onSaveSelection={handleSaveSelection}
                                      sideFilter={true}
                                    />
                                  </Accordion>
                                )}

                              {hasFabricFinishTypeOptions && (
                                <Accordion title="Finish">
                                  <FilterList
                                    // @ts-ignore

                                    filterValue={
                                      props.filter.ffifabrics_finishtype
                                    }
                                    options={fabricFinishTypeOptions}
                                    setFilterValue={
                                      props.setFabricsFinishTypeFilter
                                    }
                                    onSaveSelection={handleSaveSelection}
                                    sideFilter={true}
                                  />
                                </Accordion>
                              )}

                              {hasSqFtOptions &&
                                (location.pathname.includes("/fabric") ||
                                  props.isSearchPage) && (
                                  <Accordion title="Stock amount">
                                    <div className="stock-amount-wrapper">
                                      <SelectQuantity
                                        filter={props.filter}
                                        aggregations={props.aggregations}
                                        setQuantityFilter={
                                          props.setQuantityFilter
                                        }
                                        showLabel={false}
                                        isMobile={true}
                                      />
                                    </div>
                                  </Accordion>
                                )}
                            </>
                          )}
                          {hasQtyOptions && (
                            <Accordion title="Quantity">
                              <SelectQuantity
                                filter={props.filter}
                                aggregations={props.aggregations}
                                setQuantityFilter={props.setQuantityFilter}
                                isMobile={true}
                                showLabel={false}
                              />
                            </Accordion>
                          )}
                          {hasCustomTypeOptions ? (
                            <Accordion title="Type">
                              <FilterList
                                filterValue={
                                  props.filter.fficustopt_type_filterable
                                }
                                options={customTypeOptions}
                                setFilterValue={props.setCustomTypeFilter}
                                onSaveSelection={handleSaveSelection}
                                sideFilter={true}
                              />
                              {/* <Select
                                // isMulti
                                // wrapperClass="multi"
                                placeholder="Type"
                                className="md mr-4"
                                value={customTypeValue}
                                options={customTypeOptions}
                                onChange={handleCustomTypeChange}
                                isSearchable={false}
                              /> */}
                            </Accordion>
                          ) : (
                            <></>
                          )}
                        </>
                      }
                      {hasPriceOptions && (
                        <Accordion title="Price">
                          <div className="w-100 pl-3 pr-3">
                            <div
                              className="mt-5 w-100 mb-3 d-flex justify-content-center"
                              onMouseUp={handleDragStop}
                              onMouseMove={handleDrag}
                              // @ts-ignore
                              onTouchMove={handleDrag}
                              // @ts-ignore
                              onTouchEnd={handleDragStop}
                              tabIndex={0}
                              role="button"
                            >
                              <PriceSlider ref={trackRef} {...sliderProps} />
                            </div>
                          </div>
                        </Accordion>
                      )}
                      {hasHexclrgrpOptions && (
                        <Accordion title="Color">
                          <FilterList
                            filterValue={props.filter.ffifabrics_hexclrgrp}
                            options={hexclrgrpOptions}
                            setFilterValue={props.setHexclrgrpFilter}
                            onSaveSelection={handleSaveSelection}
                            sideFilter={true}
                          />
                        </Accordion>
                      )}
                      <div className="mt-5 w-100">
                        {/* <Button
                          color="default"
                          className={"default w-100"}
                          // direction={"mr-2"}
                          children={<span> Apply Filters</span>}
                          onClick={props.applyAllFilters}
                          onKeyUp={props.applyAllFilters}
                        /> */}
                        {isAnyFilterActive(props.filter) ? (
                          <>
                            <Button
                              color="default"
                              className={"btn-secondary w-100"}
                              // direction={"mr-2"}
                              children={<span> Reset Filters</span>}
                              onClick={() => {
                                resetFilters();
                              }}
                              onKeyUp={createEnterHandler(resetFilters)}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div
                      ref={drawerRef}
                      className={`content-wrapper w-100 d-flex align-items-center`}
                    >
                      {
                        <>
                          {(hasRetail || hasSenior || hasContract) &&
                            +!location.pathname.includes("/industry") && (
                              <SwitchFilters
                                hasRetail={hasRetail}
                                hasSenior={hasSenior}
                                hasContract={hasContract}
                                selectedValues={selectedValues}
                                setSelectedValues={setSelectedValues}
                                filter={props.filter}
                                clearFilter={resetFilters}
                                setLaneFilter={props.setLaneFilter}
                                clearLaneFilter={props.clearLaneFilter}
                                vertical={false}
                                listingProps={props.listingProps}
                                // @ts-ignore
                                industryFilter={industryFilter}
                                // className="mt-4"
                              />
                            )}
                        </>
                      }
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {props.isCollection && (
                <div className="mt-5 w-100">
                  {isAnyFilterActive(props.filter) ? (
                    <>
                      <Button
                        color="default"
                        className={"btn-secondary w-100"}
                        // direction={"mr-2"}
                        children={<span> Reset Filters</span>}
                        onClick={() => {
                          resetFilters();
                        }}
                        onKeyUp={createEnterHandler(resetFilters)}
                      />
                    </>
                  ) : null}
                </div>
              )}
            </MDBContainer>
          )}

          <Modal
            isOpen={sortModalOpen}
            onClose={setSortModalOpen.setFalse}
            title="Sort"
          >
            {sortOptions.map((option) => {
              const handleClick = () => {
                props.setSort(getSortFromOption(option));
                setSortModalOpen.setFalse();
              };
              return (
                <div
                  key={option.value}
                  className="px-5 py-4 border-bottom"
                  onClick={handleClick}
                  onKeyUp={createEnterHandler(handleClick)}
                  tabIndex={0}
                  role="button"
                >
                  <MDBTypography className="m-0 fs-14 fwregular">
                    {option.label}
                  </MDBTypography>
                </div>
              );
            })}
          </Modal>
          {props.isMobile ? (
            <Modal
              isOpen={props.filterModalOpen}
              onClose={props.setFilterModalOpen.setFalse}
              title="All Filters"
            ></Modal>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBModal
        inline={false}
        noClickableBodyWithoutBackdrop
        overflowScroll={false}
        backdropClassName="d-none"
        className="m-0 ml-auto h-100"
        // contentClassName="h-100"
        id="filter-modal"
        isOpen={props.filterModalOpen && isMobile}
        toggle={isMobile && props.setFilterModalOpen.toggle}
      >
        <MDBModalBody className="p-0 h-100 d-flex flex-column filter-modal">
          {categoryOpened ? (
            <>
              <ModalHeaderBack onBack={() => setCategoryOpened(false)}>
                Category
              </ModalHeaderBack>
              <FilterList
                filterValue={
                  // @ts-ignore
                  selectedCategory?.value ? [selectedCategory?.value] : []
                }
                options={props.categoryListDropdown}
                setFilterValue={handleCategoryChange}
                onSaveSelection={handleSaveSelection}
                isCategory={true}
                sideFilter={false}
                // setSelectedCategory={setSelectedCategory}
              />
            </>
          ) : null}
          {(() => {
            switch (selectedFilter) {
              // case Filters.lane:
              //   return (
              //     <>
              //       <ModalHeaderBack onBack={handleBack}>
              //         Industry
              //       </ModalHeaderBack>
              //       <SwitchFilters
              //         hasRetail={hasRetail}
              //         hasSenior={hasSenior}
              //         hasContract={hasContract}
              //         selectedValues={selectedValues}
              //         setSelectedValues={setSelectedValues}
              //         filter={props.filter}
              //         clearFilter={resetFilters}
              //         setLaneFilter={props.setLaneFilter}
              //         clearLaneFilter={props.clearLaneFilter}
              //         listingProps={props.listingProps}
              //         // @ts-ignore
              //         industryFilter={industryFilter}
              //         vertical
              //         onSaveSelection={handleSaveSelection}
              //       />
              //     </>
              //   );
              // case Filters.ffifabrics_collection:
              //   return (
              //     <>
              //       <ModalHeaderBack onBack={handleBack}>
              //         Collection
              //       </ModalHeaderBack>
              //       <FilterList
              //         filterValue={props.filter.ffifabrics_collection}
              //         options={fabricsCollectionOptions}
              //         setFilterValue={props.setFabricsCollectionFilter}
              //         onSaveSelection={handleSaveSelection}
              //       />
              //     </>
              //   );
              case Filters.ffifabrics_grade:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Grade
                    </ModalHeaderBack>
                    <FilterList
                      filterValue={props.filter.ffifabrics_grade}
                      options={gradeOptions}
                      setFilterValue={props.setGradeFilter}
                      onSaveSelection={handleSaveSelection}
                      sideFilter={false}
                    />
                  </>
                );
              case Filters.ffifabrics_category1:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Pattern
                    </ModalHeaderBack>
                    <FilterList
                      filterValue={props.filter.ffifabrics_category1}
                      options={filteredPatternOptions}
                      setFilterValue={props.setPatternFilter}
                      onSaveSelection={handleSaveSelection}
                      sideFilter={false}

                      // search={
                      //   <Label
                      //     text="Filter by label"
                      //     required={false}
                      //     className="mx-4"
                      //   >
                      //     <MDBInput
                      //       className="mb-0"
                      //       value={patternFilter}
                      //       onChange={handlePatternFilterChange}
                      //       hint="ex: 1100"
                      //     />
                      //   </Label>
                      // }
                    />
                  </>
                );
              case Filters.ffifabrics_hexclrgrp:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Color
                    </ModalHeaderBack>
                    <FilterList
                      filterValue={props.filter.ffifabrics_hexclrgrp}
                      options={hexclrgrpOptions}
                      setFilterValue={props.setHexclrgrpFilter}
                      onSaveSelection={handleSaveSelection}
                      sideFilter={false}
                    />
                  </>
                );
              case Filters.fficustopt_type_filterable:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Type
                    </ModalHeaderBack>
                    <FilterList
                      filterValue={props.filter.fficustopt_type_filterable}
                      options={customTypeOptions}
                      setFilterValue={props.setCustomTypeFilter}
                      onSaveSelection={handleSaveSelection}
                      sideFilter={false}
                    />
                  </>
                );
              case Filters.price:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Price
                    </ModalHeaderBack>

                    <div
                      className="mt-5 mb-3 d-flex justify-content-center flex-grow-1 overflow-auto"
                      onMouseUp={handleDragStop}
                      onMouseLeave={handleDragStop}
                      onMouseMove={handleDrag}
                      // @ts-ignore
                      onTouchMove={handleDrag}
                      // @ts-ignore
                      onTouchEnd={handleDragStop}
                      tabIndex={0}
                      role="button"
                    >
                      <PriceSlider ref={trackRef} {...sliderProps} />
                    </div>
                    <div className="m-4 p-2">
                      <MDBBtn className="m-0 w-100" onClick={handleSaveSelection}>
                        Save Selection
                      </MDBBtn>
                    </div>
                  </>
                );
              case Filters.ffifabrics_avail_filter_bucket:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Stock amount
                    </ModalHeaderBack>

                    <div
                      className="mt-5 mb-3 d-flex justify-content-center"
                      onMouseUp={handleDragStopSqFt}
                      onMouseLeave={handleDragStopSqFt}
                      onMouseMove={handleSqFtDrag}
                      // @ts-ignore
                      onTouchMove={handleSqFtDrag}
                      // @ts-ignore
                      onTouchEnd={handleDragStopSqFt}
                      tabIndex={0}
                      role="button"
                    >
                      <PriceSlider
                        ref={trackRef}
                        {...sqFtSliderProps}
                        sizeSuffix={props?.sizeSuffix}
                        isUniversal={props.isSearchPage}
                      />
                    </div>
                  </>
                );
              case Filters.ffi_qty_filter_bucket:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Stock amount
                    </ModalHeaderBack>
                    <SelectQuantity
                      filter={props.filter}
                      aggregations={props.aggregations}
                      setQuantityFilter={props.setQuantityFilter}
                      isMobile={true}
                      showLabel={true}
                    />
                  </>
                );
              case Filters.dimensions:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Dimensions
                    </ModalHeaderBack>
                    <div className="flex-grow-1 overflow-auto">
                      {!pathnameIncludes("fabrics") && (
                        <>
                          <SelectDimension
                            filter={props.filter}
                            aggregations={props.aggregations}
                            setDimensionFilter={props.setWidthFilter}
                            isMobile={true}
                            dimension="ffi_width_filter_bucket"
                          />
                          <SelectDimension
                            filter={props.filter}
                            aggregations={props.aggregations}
                            setDimensionFilter={props.setHeightFilter}
                            isMobile={true}
                            dimension="ffi_height_filter_bucket"
                          />
                          <SelectDimension
                            filter={props.filter}
                            aggregations={props.aggregations}
                            setDimensionFilter={props.setDepthFilter}
                            isMobile={true}
                            dimension="ffi_depth_filter_bucket"
                          />
                        </>
                      )}
                    </div>
                    <div className="m-4 p-2">
                      <MDBBtn className="m-0 w-100" onClick={handleSaveSelection}>
                        Save Selection
                      </MDBBtn>
                    </div>
                  </>
                );
              case Filters.ffi_collection:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Collection
                    </ModalHeaderBack>
                    <FilterList
                      filterValue={props.filter.ffi_collection}
                      options={collectionOptions}
                      setFilterValue={props.setCollectionFilter}
                      onSaveSelection={handleSaveSelection}
                      sideFilter={false}
                    />
                    {/* <div className="m-4 p-2">
                      <MDBBtn
                        className="m-0 w-100"
                        onClick={handleSaveSelection}
                      >
                        Save Selection
                      </MDBBtn>
                    </div> */}
                  </>
                );

              case Filters.product_features:
                return (
                  <>
                    <ModalHeaderBack onBack={handleBack}>
                      Product Features
                    </ModalHeaderBack>
                    <FilterList
                      filterValue={props.filter.product_features}
                      options={productFeaturesOptions}
                      setFilterValue={props.setProductFeatureFilters}
                      onSaveSelection={handleSaveSelection}
                      sideFilter={false}
                    />
                  </>
                );
                case Filters.ffi_product_type_filter:
                  return (
                    <>
                      <ModalHeaderBack onBack={handleBack}>
                        Product Types
                      </ModalHeaderBack>
                      <FilterList
                        filterValue={props.filter.ffi_product_type_filter}
                        options={productTypesOptions}
                        setFilterValue={props.setProductTypeFilters}
                        onSaveSelection={handleSaveSelection}
                        sideFilter={false}
                      />
                    </>
                  );
              default:
                return !categoryOpened ? (
                  <>
                    <ModalHeader onClose={props.setFilterModalOpen.setFalse}>
                      All Filters
                    </ModalHeader>
                    {!props.isMobile ? null : !props.isCollection ? (
                      <>
                        {(window.location.pathname.includes("/instock") ||
                          (props.isSearchPage &&
                            props.categoryListDropdown?.length > 0)) && (
                          <FilterItem
                            onSelect={() => setCategoryOpened(true)}
                          >
                            Category
                          </FilterItem>
                        )}
                        {/* {(hasRetail || hasSenior || hasContract) &&
                        !location.pathname.includes("/industry") ? (
                          <FilterItem onSelect={selectLane}>
                            Industry
                          </FilterItem>
                        ) : null} */}
                        {hasCollectionOptions && (
                          <FilterItem onSelect={selectCollection}>
                            Collection
                          </FilterItem>
                        )}
                        {hasFabricsCollectionOptions && (
                          <FilterItem onSelect={selectFabricsCollection}>
                            Collection
                          </FilterItem>
                        )}
                        {hasGradeOptions && (
                          <FilterItem onSelect={selectGrade}>
                            Grade
                          </FilterItem>
                        )}
                        {hasPatternOptions && (
                          <FilterItem onSelect={selectPattern}>
                            Pattern
                          </FilterItem>
                        )}
                        {hasHexclrgrpOptions && (
                          <FilterItem onSelect={selectHexclrgrp}>
                            Color
                          </FilterItem>
                        )}
                        {hasCustomTypeOptions && (
                          <FilterItem onSelect={selectCustomType}>
                            Type
                          </FilterItem>
                        )}
                        {hasSqFtOptions && (
                          <FilterItem onSelect={selectStockAmount}>
                            Stock amount
                          </FilterItem>
                        )}
                        {hasQtyOptions && (
                          <FilterItem onSelect={selectQtyAmount}>
                            Stock amount
                          </FilterItem>
                        )}
                        {hasPriceOptions && (
                          <FilterItem onSelect={selectPrice}>
                            Price
                          </FilterItem>
                        )}

                        {hasProductFeatures && (
                          <FilterItem onSelect={selectProductFeatures}>
                            Product Features
                          </FilterItem>
                        )}
                        {hasProductTypes && (
                          <FilterItem onSelect={selectProductTypes}>
                            Product Types
                          </FilterItem>
                        )}
                        {!pathnameIncludes("fabrics") &&
                          (checkForDimensions("ffi_width_filter_bucket") ||
                            checkForDimensions("ffi_height_filter_bucket") ||
                            checkForDimensions(
                              "ffi_depth_filter_bucket"
                          )) && (
                          <FilterItem onSelect={selectDimensions}>
                            Dimensions
                          </FilterItem>
                        )}
                        <div className="d-flex mt-4">
                          {hasNewChangeWithUrl > 1 && !whatsNew && (
                            <Switch
                              style={{ color: "#6C808B" }}
                              className="pl-xs-5 pl-4"
                              checked={!!props.filter.is_new}
                              onChange={props.toggleIsNewFilter}
                              label="New"
                              // disabled={hasNew <= 1}
                            />
                          )}
                          {isInStockProducts && isNotFabricsAvail &&
                            !window.location.pathname.includes("/instock") ? (
                              <Switch
                                style={{ color: "#6C808B" }}
                                className="pl-xs-5 pl-4"
                                checked={!!props.filter.in_stock_filter}
                                onChange={props.toggleInStockFilter}
                                label="In Stock"
                              />
                          ) : null}
                          {showReadyToShip && selectedFilter === Filters.none ? (
                            <Switch
                              style={{ color: "#6C808B" }}
                              className="pl-xs-5 pl-4"
                              checked={!!props.filter.ready_to_ship_filter}
                              onChange={toggleReadyToShip}
                              label="Ready to ship"
                            />
                          ) : null}
                          {/* {window.location.pathname.includes("/instock") ? (
                            <>
                              <Switch
                                style={{ color: "#6C808B" }}
                                className="pl-xs-5 pl-4"
                                checked={!!props.filter.ffi_leatheravl}
                                onChange={props.toggleLeatherAvailableFilter}
                                label="Available in Leather"
                              />
                              <Switch
                                style={{ color: "#6C808B" }}
                                className="pl-xs-5 pl-4"
                                checked={!!props.filter.ffi_assem_req}
                                onChange={props.toggleAssemblyFilter}
                                label="Assembly Required"
                              />
                            </>
                          ) : null} */}
                        </div>
                      </>
                    ) : (
                      <>
                        {/* {(hasRetail || hasSenior || hasContract) && (
                          <FilterItem onSelect={selectLane}>
                            Industry
                          </FilterItem>
                        )} */}
                        {hasPriceOptions && (
                          <FilterItem onSelect={selectPrice}>
                            Price
                          </FilterItem>
                        )}
                      </>
                    )}
                  </>
                ) : null;
            }
          })()}
          {props.isMobile &&
            (props.isSearchPage || props?.categoryIds?.length) &&
            !categoryOpened &&
            selectedFilter === Filters.none ? (
            <div className="px-4">
              <SearchField
                isExact={props.isExact}
                setExact={props.setExact}
                unconfirmedSearchVal={unconfirmedSearchVal}
                setUnconfirmedSearchVal={setUnconfirmedSearchVal}
                handleSearchBtn={handleSearchBtn}
                isSidebar={true}
                setSearchVal={props.setSearchVal}
                searchVal={props.searchVal}
                // @ts-ignore
                setCurrentPage={props.setCurrentPage}
              />
            </div>
          ) : null}
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default Filter;
