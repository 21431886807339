// @ts-check
import { useBoolean } from "ahooks";
import { findCategoryById } from "core/helpers/category";
import { messagesActions } from "core/state/redux/data/messages";
import _ from "lodash";
import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdbreact";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Collections from "./collections";
import Filter from "./Filter";
import { useMediaQuery } from "react-responsive";
import HeroBanner from "./heroBanner";
import Listing from "./listing";
import SkeletonCategory from "./skeleton-category";
import Toolbar from "./toolbar";
import { maxPercentage, minPercentage, priceToPercentage } from "./utils/price";
import { getAggregation, initialFilter } from "./utils/utils";
import { pathnameIncludes } from "app/utils/utils";

/**
 *
 * @type {React.FC<*>}
 */
const Category = ({
  categoryId,
  heroBannerProps,
  // showCollectionAsCategory,
  // setShowCollectionAsCategory,
  toolbarProps,
  listingProps,
  inFabricsContext,
  aggregations,
  filter,
  appliedFilter,
  setFabricsCollectionFilter,
  setGradeFilter,
  setPatternFilter,
  setHexclrgrpFilter,
  setFabricsFinishTypeFilter,
  setPriceFilter,
  gradeOptions,
  patternOptions,
  hexclrgrpOptions,
  setCollectionFilter,
  queryProps,
  favorites,
  onToggleFavorite,
  toggleIsNewFilter,
  setLaneFilter,
  clearFilter,
  clearLaneFilter,
  isLoggedIn,
  sort,
  setSort,
  toggleIsAsc,
  isFabricOrFinishesCategory,
  isFinish,
  data,
  setCustomTypeFilter,
  toggleInStockFilter,
  toggleAssemblyFilter,
  toggleLeatherAvailableFilter,
  categoryListDropdown,
  setCategoryFilter,
  searchPageInput,
  categoryName,
  optionsVal,
  isSearchPage,
  setSquareFeetFilter,
  searchVal,
  setSearchVal,
  isExact,
  setExact,
  loadingData,
  isInStock,
  toogleReadyToShip,
  setQuantityFilter,
  setWidthFilter,
  setHeightFilter,
  setDepthFilter,
  setCurrentPage = () => {},
  categoryIds,
  setFinishCategory,
  finishCategory,
  applyAllFilters,
  setProductFeatureFilters,
  setProductTypeFilters,
}) => {
  const dispatch = useDispatch();
  let isMobile = useMediaQuery({ maxWidth: 992 });

  const thisCategory = findCategoryById(categoryId) || categoryIds?.length;
  const priceAggregation = React.useMemo(() => {
    return getAggregation("price", aggregations);
  }, [aggregations]);

  const sizeSuffix = React.useMemo(() => {
    let prefix = "";
    let aggregation = aggregations.find(
      (/** @type {{ attribute_code: string; }} */ agg) =>
        agg.attribute_code === "ffifabrics_fabric"
    );
    prefix = isSearchPage
      ? "yards/sqft"
      : aggregation?.options?.[0]?.value === "1"
      ? "yd"
      : "sq. ft.";
    return prefix;
    // return getAggregation("ffifabrics_fabric", aggregations);
  }, [aggregations]);

  const sqFtAggregation = React.useMemo(() => {
    return getAggregation("ffifabrics_avail_filter_bucket", aggregations);
  }, [aggregations]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [localCategory, setLocalCategory] = useState(categoryId);

  const [minSqFt, setMinSqFt] = useState(0);
  const [maxSqFt, setMaxSqFt] = useState(0);
  const [filterModalOpen, setFilterModalOpen] = useBoolean(!isMobile);

  const handleFilterOpen = React.useCallback(
    () => setFilterModalOpen.toggle(),
    [setFilterModalOpen]
  );

  React.useEffect(() => {
    if (priceAggregation) {
      let mP = 0;
      if (categoryId === localCategory) {
        mP = minPrice;
      } else {
        setLocalCategory(categoryId);
      }
      setMinPrice(
        Math.min(
          mP,
          parseInt(priceAggregation?.options?.[0]?.value.split("_")?.[0] || "")
        )
      );
    }
  }, [priceAggregation?.options, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const options = priceAggregation?.options;
    if (options) {
      let mP = 0;
      if (categoryId === localCategory) {
        mP = maxPrice;
      } else {
        setLocalCategory(categoryId);
      }
      setMaxPrice(
        Math.max(
          mP,
          parseInt(options?.[options.length - 1]?.value.split("_")?.[1] || "")
        )
      );
    }
  }, [priceAggregation?.options, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (sqFtAggregation) {
      let mP = 0;
      if (categoryId === localCategory) {
        mP = minSqFt;
      } else {
        setLocalCategory(categoryId);
      }
      setMinSqFt(
        Math.min(
          mP,
          parseInt(sqFtAggregation?.options?.[0]?.value.split("_")?.[0] || "")
        )
      );
    }
  }, [sqFtAggregation?.options, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const options = sqFtAggregation?.options;
    if (options) {
      let mP = 0;
      if (categoryId === localCategory) {
        mP = maxSqFt;
      } else {
        setLocalCategory(categoryId);
      }
      setMaxSqFt(
        Math.max(
          mP,
          parseInt(options?.[options.length - 1]?.value.split("_")?.[1] || "")
        )
      );
    }
  }, [sqFtAggregation?.options, categoryId]);

  const [fromPercentage, setFromPercentage] = React.useState(() =>
    filter.price?.from
      ? priceToPercentage(maxPrice, filter.price.from)
      : minPercentage
  );
  const [toPercentage, setToPercentage] = React.useState(
    filter.price?.from
      ? priceToPercentage(maxPrice, filter.price.to)
      : maxPercentage
  );

  const [fromSqFtPercentage, setFromSqFtPercentage] = React.useState(() =>
    filter.ffifabrics_avail_filter_bucket?.from
      ? priceToPercentage(maxSqFt, filter.ffifabrics_avail_filter_bucket.from)
      : minPercentage
  );
  const [toSqFtPercentage, setToSqFtPercentage] = React.useState(
    filter.ffifabrics_avail_filter_bucket?.from
      ? priceToPercentage(maxSqFt, filter.ffifabrics_avail_filter_bucket.to)
      : maxPercentage
  );
  const areItemsEmpty = React.useMemo(
    () =>
      !thisCategory ||
      // @ts-ignore
      // (!thisCategory.isCollection && _.isEmpty(listingProps)),
      _.isEmpty(listingProps),
    [thisCategory, listingProps]
  );

  React.useEffect(() => {
    if (areItemsEmpty && !loadingData && !pathnameIncludes("build-your-own")) {
      if (
        filter?.ffi_width_filter_bucket ||
        filter?.ffi_height_filter_bucket ||
        filter?.ffi_depth_filter_bucket
      ) {
        dispatch(
          messagesActions.addMessage(
            "No items are available with specified filters.",
            "warning"
          )
        );
        clearFilter();
      } else if (categoryIds?.length && areItemsEmpty)
        setFinishCategory(categoryIds?.[0]);
      else if (filter.ffi_qty_filter_bucket) {
        dispatch(
          messagesActions.addMessage(
            "No items are available with specified filters.",
            "warning"
          )
        );
        setQuantityFilter(null);
      }
      if (filter.price) {
        dispatch(
          messagesActions.addMessage(
            "No items are available with specified filters.",
            "warning"
          )
        );

        setFromPercentage(minPercentage);
        setToPercentage(maxPercentage);
        setPriceFilter(null);
      } else if (filter.lane) {
        dispatch(
          messagesActions.addMessage(
            "No items are available with specified filters.",
            "warning"
          )
        );
        clearFilter();
      }
      if (!isSearchPage && !loadingData && !pathnameIncludes("graded-in")) {
        dispatch(
          messagesActions.addMessage(
            "No items are available with specified filters.",
            "warning"
          )
        );
        clearFilter();
      }
    } else {
      if (filter !== initialFilter) {
        // * Necessary - in order for the user not to get stuck in an unrecoverable state
      }
    }
  }, [
    areItemsEmpty,
    filter,
    dispatch,
    clearFilter,
    maxPrice,
    minPrice,
    setPriceFilter,
    loadingData,
    categoryIds?.length,
  ]);

  return (
    <>
      {/* @ts-ignore */}
      {thisCategory?.isCollection &&
      !window.location.pathname.includes("search-results") ? (
        // @ts-ignore
        <>
          <Collections
            data={data}
            category={thisCategory}
            maxPrice={maxPrice}
            minPrice={minPrice}
            toPercentage={toPercentage}
            fromPercentage={fromPercentage}
            priceAggregation={priceAggregation}
            categoryMain={categoryId}
            heroBannerProps={heroBannerProps}
            queryProps={queryProps}
            listingProps={listingProps}
            toolbarProps={toolbarProps}
            setLaneFilter={setLaneFilter}
            clearFilter={clearFilter}
            clearLaneFilter={clearLaneFilter}
            appliedFilter={appliedFilter}
            filter={filter}
            inFabricsContext={inFabricsContext}
            aggregations={aggregations}
            setFabricsCollectionFilter={setFabricsCollectionFilter}
            setGradeFilter={setGradeFilter}
            setPatternFilter={setPatternFilter}
            setHexclrgrpFilter={setHexclrgrpFilter}
            setFabricsFinishTypeFilter={setFabricsFinishTypeFilter}
            setFromPercentage={setFromPercentage}
            setToPercentage={setToPercentage}
            setPriceFilter={setPriceFilter}
            gradeOptions={gradeOptions}
            patternOptions={patternOptions}
            hexclrgrpOptions={hexclrgrpOptions}
            setCollectionFilter={setCollectionFilter}
            toggleIsNewFilter={toggleIsNewFilter}
            isLoggedIn={isLoggedIn}
            sort={sort}
            setSort={setSort}
            toggleIsAsc={toggleIsAsc}
            toggleInStockFilter={toggleInStockFilter}
            toogleReadyToShip={toogleReadyToShip}
            loadingData={loadingData}
            setSearchVal={setSearchVal}
            isMobile={isMobile}
            setProductFeatureFilters={setProductFeatureFilters}
            setProductTypeFilters={setProductTypeFilters}
            setWidthFilter={setWidthFilter}
            setHeightFilter={setHeightFilter}
            setDepthFilter={setDepthFilter}
            // showCollectionAsCategory={showCollectionAsCategory}
            // setShowCollectionAsCategory={setShowCollectionAsCategory}
          />
        </>
      ) : (
        <MDBContainer fluid className="">
          {!categoryIds?.length ? (
            <MDBRow className="justify-content-center  " id="plp-hero-image">
              <HeroBanner {...heroBannerProps} category={thisCategory} isSearchPage={isSearchPage} />
            </MDBRow>
          ) : null}

          <MDBContainer fluid className="plp-container-wrap ">
            <Filter
              // @ts-ignore
              categoryId={categoryId}
              finishCategory={finishCategory}
              categoryIds={categoryIds}
              setFinishCategory={setFinishCategory}
              listingProps={listingProps}
              inFabricsContext={inFabricsContext}
              aggregations={aggregations}
              filter={filter}
              appliedFilter={appliedFilter}
              setFabricsCollectionFilter={setFabricsCollectionFilter}
              setGradeFilter={setGradeFilter}
              setPatternFilter={setPatternFilter}
              setHexclrgrpFilter={setHexclrgrpFilter}
              setFabricsFinishTypeFilter={setFabricsFinishTypeFilter}
              priceAggregation={priceAggregation ?? null}
              minPrice={minPrice}
              maxPrice={maxPrice}
              maxSqFt={maxSqFt}
              minSqFt={minSqFt}
              toSqFtPercentage={toSqFtPercentage}
              fromSqFtPercentage={fromSqFtPercentage}
              setToSqFtPercentage={setToSqFtPercentage}
              setFromSqFtPercentage={setFromSqFtPercentage}
              setSquareFeetFilter={setSquareFeetFilter}
              sqFtAggregation={sqFtAggregation ?? null}
              fromPercentage={fromPercentage}
              setFromPercentage={setFromPercentage}
              toPercentage={toPercentage}
              setToPercentage={setToPercentage}
              setPriceFilter={setPriceFilter}
              gradeOptions={gradeOptions}
              patternOptions={patternOptions}
              hexclrgrpOptions={hexclrgrpOptions}
              setCollectionFilter={setCollectionFilter}
              setCustomTypeFilter={setCustomTypeFilter}
              toggleIsNewFilter={toggleIsNewFilter}
              toggleInStockFilter={toggleInStockFilter}
              toggleAssemblyFilter={toggleAssemblyFilter}
              toggleLeatherAvailableFilter={toggleLeatherAvailableFilter}
              setLaneFilter={setLaneFilter}
              clearFilter={clearFilter}
              clearLaneFilter={clearLaneFilter}
              isLoggedIn={isLoggedIn}
              sort={sort}
              setSort={setSort}
              toggleIsAsc={toggleIsAsc}
              isCollection={false}
              categoryListDropdown={categoryListDropdown}
              setCategoryFilter={setCategoryFilter}
              preselectedIndustry={optionsVal}
              isSearchPage={isSearchPage}
              sizeSuffix={sizeSuffix}
              setSearchVal={setSearchVal}
              searchVal={searchVal}
              isExact={isExact}
              setExact={setExact}
              categoryName={categoryName}
              isInStock={isInStock}
              toogleReadyToShip={toogleReadyToShip}
              setQuantityFilter={setQuantityFilter}
              setWidthFilter={setWidthFilter}
              setHeightFilter={setHeightFilter}
              setDepthFilter={setDepthFilter}
              setCurrentPage={setCurrentPage}
              paginationProps={toolbarProps}
              setFilterModalOpen={setFilterModalOpen}
              filterModalOpen={filterModalOpen}
              handleFilterOpen={handleFilterOpen}
              isMobile={isMobile}
              applyAllFilters={applyAllFilters}
              setProductFeatureFilters={setProductFeatureFilters}
              setProductTypeFilters={setProductTypeFilters}
              isTopFilter={true}
            />

            <MDBRow className="mt-5 ">
              {filterModalOpen && (
                <MDBCol
                  size="3"
                  className={!isMobile ? "d-none d-md-flex mb-5 sticky-filter" : "max-w-0 p-0"}
                >
                  <Filter
                    // @ts-ignore
                    categoryId={categoryId}
                    finishCategory={finishCategory}
                    categoryIds={categoryIds}
                    setFinishCategory={setFinishCategory}
                    listingProps={listingProps}
                    inFabricsContext={inFabricsContext}
                    aggregations={aggregations}
                    filter={filter}
                    appliedFilter={appliedFilter}
                    setFabricsCollectionFilter={setFabricsCollectionFilter}
                    setGradeFilter={setGradeFilter}
                    setPatternFilter={setPatternFilter}
                    setHexclrgrpFilter={setHexclrgrpFilter}
                    setFabricsFinishTypeFilter={setFabricsFinishTypeFilter}
                    priceAggregation={priceAggregation ?? null}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    maxSqFt={maxSqFt}
                    minSqFt={minSqFt}
                    toSqFtPercentage={toSqFtPercentage}
                    fromSqFtPercentage={fromSqFtPercentage}
                    setToSqFtPercentage={setToSqFtPercentage}
                    setFromSqFtPercentage={setFromSqFtPercentage}
                    setSquareFeetFilter={setSquareFeetFilter}
                    sqFtAggregation={sqFtAggregation ?? null}
                    fromPercentage={fromPercentage}
                    setFromPercentage={setFromPercentage}
                    toPercentage={toPercentage}
                    setToPercentage={setToPercentage}
                    setPriceFilter={setPriceFilter}
                    gradeOptions={gradeOptions}
                    patternOptions={patternOptions}
                    hexclrgrpOptions={hexclrgrpOptions}
                    setCollectionFilter={setCollectionFilter}
                    setCustomTypeFilter={setCustomTypeFilter}
                    toggleIsNewFilter={toggleIsNewFilter}
                    toggleInStockFilter={toggleInStockFilter}
                    toggleAssemblyFilter={toggleAssemblyFilter}
                    toggleLeatherAvailableFilter={toggleLeatherAvailableFilter}
                    setLaneFilter={setLaneFilter}
                    clearFilter={clearFilter}
                    clearLaneFilter={clearLaneFilter}
                    isLoggedIn={isLoggedIn}
                    sort={sort}
                    setSort={setSort}
                    toggleIsAsc={toggleIsAsc}
                    isCollection={false}
                    categoryListDropdown={categoryListDropdown}
                    setCategoryFilter={setCategoryFilter}
                    preselectedIndustry={optionsVal}
                    isSearchPage={isSearchPage}
                    sizeSuffix={sizeSuffix}
                    setSearchVal={setSearchVal}
                    searchVal={searchVal}
                    isExact={isExact}
                    setExact={setExact}
                    categoryName={categoryName}
                    isInStock={isInStock}
                    toogleReadyToShip={toogleReadyToShip}
                    setQuantityFilter={setQuantityFilter}
                    setWidthFilter={setWidthFilter}
                    setHeightFilter={setHeightFilter}
                    setDepthFilter={setDepthFilter}
                    setCurrentPage={setCurrentPage}
                    paginationProps={toolbarProps}
                    setFilterModalOpen={setFilterModalOpen}
                    filterModalOpen={filterModalOpen}
                    handleFilterOpen={handleFilterOpen}
                    isMobile={isMobile}
                    isTopFilter={false}
                    setProductFeatureFilters={setProductFeatureFilters}
                    setProductTypeFilters={setProductTypeFilters}
                    applyAllFilters={applyAllFilters}
                  />
                </MDBCol>
              )}
              <MDBCol className="p-0">
                <MDBContainer fluid>
                  {/* {((toolbarProps.totalCount > 0 || isSearchPage) && ( */}
                  <>
                    {/* <Toolbar
                    {...toolbarProps}
                    searchPageInput={searchPageInput}
                    optionsVal={optionsVal}
                    categoryName={categoryName}
                    loadingData={loadingData}
                  /> */}

                    {!thisCategory ||
                    (loadingData && !pathnameIncludes("collections")) ? (
                      <SkeletonCategory />
                    ) : (
                      <Listing
                        items={listingProps}
                        favorites={favorites}
                        onToggleFavorite={onToggleFavorite}
                        categoryName={categoryName}
                        isSearchPage={isSearchPage}
                        // @ts-ignore
                        isFabricOrFinishesCategory={isFabricOrFinishesCategory}
                        isFinish={isFinish}
                      />
                    )}
                    <Toolbar {...toolbarProps} />
                  </>
                  {/* )) || */}

                  {/* (!thisCategory ||
                  (loadingData && !pathnameIncludes("collections")) ? (
                    <SkeletonCategory loadingPage />
                  ) : (
                    <MDBRow>
                      <MDBCol>
                        <MDBTypography
                          tag="h4"
                          className={`fwregular text-default my-5`}
                        >
                          There are no products currently in this category
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                  ))} */}
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>
      )}
    </>
  );
};

export default Category;
